<script setup>
import { defineProps } from 'vue';
import formatDecimal from '../../utils/formatUtils';

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  suffix: {
    type: String,
    default: '',
  },
  preffix: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: 'No data',
  },
  delta: {
    type: Number,
    default: 0,
  },
});

const formattedDelta = formatDecimal(props.delta, 0, 2);

</script>

<template>
  <article class="w-2 flex flex-column justify-content-center align-items-center p-2 metric border-primary">
    <p class="mb-0 text-md">
      {{ name }}
    </p>
    <strong class="flex align-items-center metric--number text-2xl">{{ preffix }} {{ value }} {{ suffix }} <i
      v-if="delta !== 0"
      v-tooltip="`Diff with previous month: ${delta > 0 ? '+' : ''}${formattedDelta}`"
      :class="`pi ${delta > 0 ? 'pi-arrow-up-right' : 'pi-arrow-down-right'} pl-1`"
    /></strong>
  </article>
</template>

<style scoped lang="scss">
  .metric {
    width: fit-content;
    border: solid 1px black;
    border-radius: 8px;

    &--number {
      margin-left: 14px;
    }

    &--annotation {
      font-size: 0.5rem;
    }
  }
</style>
