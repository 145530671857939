<template>
  <div class="flex predicate-gap">
    <Dropdown
      v-model="metric"
      class="metric-dropdown"
      :options="metrics"
      option-label="name"
      placeholder="Select a metric"
      :disabled="!editable"
      @change="updateMetrics"
    />

    <Dropdown
      v-model="operand"
      :options="metricOperands"
      option-label="name"
      placeholder="Select an operand"
      :disabled="!editable"
      @change="updateOperand"
    />

    <InputNumber
      v-model="value"
      :min="0"
      :use-grouping="false"
      :max-fraction-digits="2"
      :allow-empty="true"
      :disabled="!editable"
      :suffix="metricSuffix"
      locale="en-US"
      @input="updateValue"
    />
  </div>
</template>
<script>
import { METRICS, METRIC_OPERANDS } from '../../../../constants/bidRulesConstants';

// eslint-disable-next-line no-unused-vars
const PERCENTAGE_METRICS_VALUES = ['TRUE_ACOS', 'ACOS', 'CTR'];

export default {
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      metricOperands: METRIC_OPERANDS,
      metrics: METRICS,
      operand: this.modelValue.operand,
      metric: this.modelValue.metric,
      value: this.modelValue.value,
    };
  },
  computed: {
    metricSuffix() {
      return null;
      // Suffix causes visual bugs when used with decimal
      // return PERCENTAGE_METRICS_VALUES.includes(this.metric.value) ? '%' : null;
    },
  },
  watch: {
    'modelValue.value': {
      handler(val) {
        if (typeof val === 'number') {
          this.value = val;
        }
      },
    },
    'modelValue.operand': {
      handler(val) {
        if (typeof val === 'object') {
          this.operand = val;
        }
      },
      deep: true,
    },
    'modelValue.metric': {
      handler(val) {
        if (typeof val === 'object') {
          this.metric = val;
        }
      },
      deep: true,
    },
  },
  methods: {
    updateMetrics(e) {
      this.$emit('update:modelValue', { ...this.modelValue, metric: e.value });
    },
    updateOperand(e) {
      this.$emit('update:modelValue', { ...this.modelValue, operand: e.value });
    },
    updateValue(e) {
      this.$emit('update:modelValue', { ...this.modelValue, value: e });
    },

  },
};
</script>

<style scoped>
  .predicate-gap {
    gap: 1rem;
  }
  .metric-dropdown {
    width: 12rem;
  }
</style>
