import { markRaw, defineAsyncComponent } from 'vue';
import {
  formatCtr, currencySymbolFromCode, formatTrueAcos, formatAcos, formatMonetaryValue,
} from './formatUtils';
import COLUMN_TOTAL_AGGREGATION from '../constants/tableConstants';

/**
 * Generates the common columns of the reports tables
 * @param {String} idPrefix - Prefix of the column IDs
 *  @param {Number} royalties - Royalties used to calculate some column values
 *  @param {Object} profile - Current account profile
 *  @return {Array} generated common columns
 */

const generateCommonColumns = (profile) => {
  const currencySymbol = currencySymbolFromCode(profile().currencyCode);
  return [
    {
      id: 'impressions',
      field: 'impressions',
      header: 'Impressions',
      sortable: true,
      numeric: true,
      decimal: false,
      width: '130px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: null,
      },
    },
    {
      id: 'clicks',
      field: 'clicks',
      header: 'Clicks',
      sortable: true,
      numeric: true,
      decimal: false,
      width: '100px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: null,
      },
    },
    {
      id: 'ctr',
      field: 'ctr',
      header: 'CTR',
      sortable: true,
      numeric: true,
      decimal: true,
      width: '100px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: (data) => (formatCtr(data)),
      },
      aggregation: COLUMN_TOTAL_AGGREGATION.CTR,
    },
    {
      id: 'cost',
      field: 'cost',
      header: 'Spend',
      sortable: true,
      numeric: true,
      decimal: true,
      visible: true,
      alwaysVisible: true,
      width: '100px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: (data) => (formatMonetaryValue(data, currencySymbol, 'cost')),
      },
    },
    {
      id: 'cpc',
      field: 'cpc',
      header: 'CPC',
      sortable: true,
      numeric: true,
      decimal: true,
      width: '100px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: (data) => (formatMonetaryValue(data, currencySymbol, 'cpc')),
      },
      aggregation: COLUMN_TOTAL_AGGREGATION.CPC,
    },
    {
      id: 'orders',
      field: 'purchases14d',
      header: 'Orders',
      sortable: true,
      numeric: true,
      visible: true,
      alwaysVisible: true,
      decimal: false,
      width: '100px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: null,
      },
    },
    {
      id: 'costPerOrders',
      field: 'costPerOrder',
      header: 'Cost Per Order',
      sortable: true,
      numeric: true,
      decimal: false,
      width: '150px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: (data) => (formatMonetaryValue(data, currencySymbol, 'costPerOrder')),
      },
    },
    {
      id: 'sales',
      field: 'sales14d',
      header: 'Sales',
      sortable: true,
      numeric: true,
      decimal: true,
      visible: true,
      width: '100px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: (data) => (formatMonetaryValue(data, currencySymbol, 'sales14d')),
      },
    },
    {
      id: 'regularAcos',
      field: 'acos',
      header: 'ACOS',
      sortable: true,
      numeric: true,
      decimal: false,
      width: '170px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: (data) => (formatAcos(data)),
      },
      aggregation: COLUMN_TOTAL_AGGREGATION.SUM,
    },
    {
      id: 'royalties',
      field: 'royalties',
      title: 'Estimated Sales Royalties',
      header: markRaw(defineAsyncComponent(() => import('../components/dashboard/table/EstimatedSalesRoyaltiesHeader.vue'))),
      sortable: true,
      numeric: true,
      visible: true,
      alwaysVisible: true,
      decimal: true,
      width: '240px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: (data) => (formatMonetaryValue(data, currencySymbol, 'royalties')),
      },
    },
    {
      id: 'kenpReads',
      field: 'kindleEditionNormalizedPagesRead14d',
      header: 'KENP Reads',
      sortable: true,
      numeric: true,
      decimal: true,
      visible: true,
      width: '150px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: (data) => data.kindleEditionNormalizedPagesRead14d ?? 0,
      },
    },
    {
      id: 'borrows',
      field: 'borrows',
      title: 'Borrows',
      header: markRaw(defineAsyncComponent(() => import('../components/dashboard/table/BorrowsHeader.vue'))),
      sortable: true,
      numeric: true,
      decimal: true,
      visible: true,
      width: '180px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: (data) => data.borrows ?? 0,
      },
    },
    {
      id: 'costPerBorrow',
      field: 'costPerBorrow',
      title: 'Cost per Borrow',
      header: markRaw(defineAsyncComponent(() => import('../components/dashboard/table/CostPerBorrowHeader.vue'))),
      sortable: true,
      numeric: true,
      decimal: true,
      width: '180px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: (data) => (formatMonetaryValue(data, currencySymbol, 'costPerBorrow')),
      },
    },
    {
      id: 'kenpRoyalties',
      field: 'kindleEditionNormalizedPagesRoyalties14d',
      header: 'KENP Royalties',
      sortable: true,
      numeric: true,
      decimal: true,
      visible: true,
      width: '150px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: (data) => (formatMonetaryValue(data, currencySymbol, 'kindleEditionNormalizedPagesRoyalties14d')),
      },
    },
    {
      id: 'adjustedRevenue',
      field: 'adjustedRevenue',
      title: 'Adjusted Revenue',
      header: markRaw(defineAsyncComponent(() => import('../components/dashboard/table/AdjustedRevenueHeader.vue'))),
      sortable: true,
      numeric: true,
      visible: true,
      alwaysVisible: true,
      decimal: true,
      width: '180px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: (data) => (formatMonetaryValue(data, currencySymbol, 'adjustedRevenue')),
      },
      aggregation: COLUMN_TOTAL_AGGREGATION.TRUE_ACOS,
    },
    {
      id: 'totalCostPerUnit',
      field: 'costPerUnit',
      title: 'Total Cost per Unit',
      header: markRaw(defineAsyncComponent(() => import('../components/dashboard/table/TotalCostPerUnitHeader.vue'))),
      sortable: true,
      numeric: true,
      decimal: true,
      visible: true,
      alwaysVisible: true,
      width: '180px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: (data) => (formatMonetaryValue(data, currencySymbol, 'costPerUnit')),
      },
    },
    {
      id: 'acos',
      field: 'trueAcos',
      title: 'Estimated ACOR',
      header: markRaw(defineAsyncComponent(() => import('../components/dashboard/table/EstimatedAcorHeader.vue'))),
      sortable: true,
      numeric: true,
      visible: true,
      alwaysVisible: true,
      decimal: false,
      width: '180px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: (data) => (formatTrueAcos(data)),
      },
      aggregation: COLUMN_TOTAL_AGGREGATION.TRUE_ACOS,
    },
  ];
};

export default generateCommonColumns;
