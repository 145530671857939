const CATEGORY_AUTHOR = 'Author';
const CATEGORY_PUBLISHER = 'Publishing Company';
const CATEGORY_MARKETER = 'Marketing Agency / Freelance Marketer';
const CATEGORY_OTHER = 'Other, please specify';

const SIGNUP_CATEGORIES = [CATEGORY_AUTHOR, CATEGORY_MARKETER, CATEGORY_PUBLISHER, CATEGORY_OTHER];

const BOOK_GENRE_ROMANCE = 'Romance';
const BOOK_GENRE_FANTASY = 'Fantasy';
const BOOK_GENRE_THRILLER = 'Thriller';
const BOOK_GENRE_CHILDREN = 'Children';
const BOOK_GENRE_MYSTERY = 'Mystery';
const BOOK_GENRE_HISTORICAL = 'Historical';
const BOOK_GENRE_SCIENCE_FICTION = 'Science fiction';
const BOOK_GENRE_OTHER = 'Other, please specify';

const BOOK_GENRES = [BOOK_GENRE_ROMANCE, BOOK_GENRE_FANTASY, BOOK_GENRE_THRILLER, BOOK_GENRE_CHILDREN, BOOK_GENRE_MYSTERY, BOOK_GENRE_HISTORICAL, BOOK_GENRE_SCIENCE_FICTION, BOOK_GENRE_OTHER];

export {
  SIGNUP_CATEGORIES, CATEGORY_OTHER, BOOK_GENRES, BOOK_GENRE_OTHER,
};
