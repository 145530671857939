import axios from 'axios';
import { TOKEN_KEY } from '../constants/storageConstants';

const getToken = () => {
  const token = window.localStorage.getItem(TOKEN_KEY);
  if (!token) throw new Error('noTokenException');
  return `Bearer ${token}`;
};

const getAuthorizedConfig = () => axios.create({
  baseURL: '/api/private',
  headers: { Authorization: getToken() },
});

const getMockConfig = (mockId) => axios.create({
  baseURL: '/api/private',
  headers: { Authorization: getToken(), 'Mock-Account-Id': mockId },
});

const getConfig = () => axios.create({
  baseURL: '/api/public',
});

export { getConfig, getAuthorizedConfig, getMockConfig };
