<template>
  <div class="py-5 text-left">
    <Button
      :loading="executingBidRules"
      class="p-button-secondary"
      @click="executeBidRules"
    >
      Execute Manual Rules
    </Button>
  </div>
  <div class="pb-5">
    <BidRuleCreation
      :selected-profile="selectedProfile"
      :campaigns="campaigns"
      :bid-rule-templates="bidRuleTemplates"
      @createBidRule="createBidRuleHandler"
    />
  </div>
  <BidRuleListing
    :selected-profile="selectedProfile"
    :campaigns="campaigns"
    :bid-rules="bidRules"
    @deleteBidRule="deleteBidRuleHandler"
  />
</template>

<script>
import BidRuleCreation from './BidRuleCreation.vue';
import BidRuleListing from './BidRuleListing.vue';
import A2CAClient from '../../../../api/a2caAuthorizedClient';

export default {
  components: { BidRuleCreation, BidRuleListing },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    bidRules: {
      type: Array,
      required: true,
    },
    bidRuleTemplates: {
      type: Array,
      required: true,
    },
  },
  emits: ['clear-selected-profile', 'change-menu-layout', 'createBidRule', 'deleteBidRule'],
  data() {
    return {
      executingBidRules: false,
    };
  },
  methods: {
    createBidRuleHandler(createdBidRule) {
      this.$emit('createBidRule', createdBidRule);
    },
    deleteBidRuleHandler(bidRuleId) {
      this.$emit('deleteBidRule', bidRuleId);
    },
    async executeBidRules() {
      this.executingBidRules = true;
      const client = new A2CAClient(this.query);
      const executionResult = await client.executeBidRules(this.selectedProfile.profileId, this.selectedProfile.region);
      if (executionResult === 'OK') {
        this.$toast.add({
          severity: 'success',
          summary: 'Running bid rules',
          detail: 'Bid rule execution started',
          life: 1500,
        });
      } else if (executionResult === 'NO_SALES_ROYALTIES') {
        this.$toast.add({
          severity: 'error',
          summary: 'No bid rules run',
          detail: 'Net Royalty Rate is not set.',
          life: 1500,
        });
      } else if (executionResult === 'NO_PROFILE') {
        this.$toast.add({
          severity: 'error',
          summary: 'No bid rules run',
          detail: 'Could not find current profile information.',
          life: 1500,
        });
      } else if (executionResult === 'NO_MATCHING_ENVIRONMENT') {
        this.$toast.add({
          severity: 'error',
          summary: 'No bid rules run',
          detail: 'Account is not allowed to run rules in the current environment.',
          life: 1500,
        });
      } else if (executionResult === 'MAX_AD_SPEND') {
        this.$toast.add({
          severity: 'error',
          summary: 'Max ad spend reached',
          detail: 'You have reached the max ad spend for your current plan; cannot execute bid rules.',
          life: 3000,
        });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'No bid rules run',
          detail: 'There are no bid rules created.',
          life: 1500,
        });
      }
      this.executingBidRules = false;
    },
  },
};
</script>
