/* eslint-disable no-param-reassign */
import {
  METRIC_CREATION_METRICS_ERROR,
  METRIC_CREATION_ERROR,
  METRIC_CREATION_NAME_ERROR,
  BID_RULE_CREATION_ERROR,
  BID_RULE_PREDICATE_CREATION_ERROR,
  BID_RULE_RECURRENCE_CREATION_ERROR,
  METRIC_MINMAX_ERROR,
} from '../constants/toastConstants';
import { isBigProfile } from '../constants/bidRulesConstants';
import { isDayOfWeekRecurrence } from './bidRuleUtils';

const validateForm = (component, campaignLength) => {
  const isBigCampaignProfile = isBigProfile(campaignLength);

  if (
    component.currentBidRule.campaignTargets.length === 0
    && (!component.currentBidRule.allCampaignsSelected || !isBigCampaignProfile)
  ) {
    component.$toast.add({
      severity: 'error',
      summary: BID_RULE_CREATION_ERROR.summary,
      detail: BID_RULE_CREATION_ERROR.detail,
      life: 3000,
    });
    component.loading = false;
    return false;
  }

  if (
    component.currentBidRule.predicates.some(
      (predicate) => predicate.value.value === null,
    )
  ) {
    component.$toast.add({
      severity: 'error',
      summary: BID_RULE_PREDICATE_CREATION_ERROR.summary,
      detail: BID_RULE_PREDICATE_CREATION_ERROR.detail,
      life: 3000,
    });
    component.loading = false;
    return false;
  }

  if (
    isDayOfWeekRecurrence(component.currentBidRule.recurrence.type.value)
    && component.currentBidRule.recurrence.dayOfWeek === undefined
  ) {
    component.$toast.add({
      severity: 'error',
      summary: BID_RULE_RECURRENCE_CREATION_ERROR.summary,
      detail: BID_RULE_RECURRENCE_CREATION_ERROR.detail,
      life: 3000,
    });
    component.loading = false;
    return false;
  }

  return true;
};

const validateMetricForm = (component, campaignLength) => {
  const isBigCampaignProfile = isBigProfile(campaignLength);
  if (
    component.currentMetric.campaigns.length === 0
    && (!component.currentMetric.allCampaignsSelected || !isBigCampaignProfile)
  ) {
    component.$toast.add({
      severity: 'error',
      summary: METRIC_CREATION_ERROR.summary,
      detail: METRIC_CREATION_ERROR.detail,
      life: 3000,
    });
    component.loading = false;
    return false;
  }

  if (
    component.currentMetric.name === null
    || component.currentMetric.name === ''
  ) {
    component.$toast.add({
      severity: 'error',
      summary: METRIC_CREATION_NAME_ERROR.summary,
      detail: METRIC_CREATION_NAME_ERROR.detail,
      life: 3000,
    });
    component.loading = false;
    return false;
  }

  if (
    component.currentMetric.metrics.salesRoyaltiesPercentage === null
    || component.currentMetric.metrics.acorGoal === null
    || component.currentMetric.metrics.minBid === null
    || component.currentMetric.metrics.maxBid === null
  ) {
    component.$toast.add({
      severity: 'error',
      summary: METRIC_CREATION_METRICS_ERROR.summary,
      detail: METRIC_CREATION_METRICS_ERROR.detail,
      life: 3000,
    });
    component.loading = false;
    return false;
  }

  if (
    component.currentMetric.metrics.minBid
    > component.currentMetric.metrics.maxBid
  ) {
    component.$toast.add({
      severity: 'error',
      summary: METRIC_MINMAX_ERROR.summary,
      detail: METRIC_MINMAX_ERROR.detail,
      life: 3000,
    });
    component.loading = false;
    return false;
  }

  return true;
};

export { validateMetricForm };
export default validateForm;
