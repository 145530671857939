export const accumulateGroupedKpi = (acc, obj, key, mergeData) => {
  if (!acc[key]) {
    if (mergeData) {
      acc[key] = { ...obj, ...mergeData };
    } else {
      acc[key] = obj;
    }

    if (!acc[key].kindleEditionNormalizedPagesRoyalties14d) {
      acc[key].kindleEditionNormalizedPagesRoyalties14d = 0;
    }
  } else {
    acc[key].clicks += obj.clicks;
    acc[key].cost += obj.cost;
    acc[key].impressions += obj.impressions;
    acc[key].sales14d += obj.sales14d;
    acc[key].purchases14d += obj.purchases14d;
    acc[key].kindleEditionNormalizedPagesRoyalties14d += (obj?.kindleEditionNormalizedPagesRoyalties14d ?? 0);
  }

  return acc;
};

export const tryParseContent = (content) => (typeof content === 'string' ? JSON.parse(content) : content);
