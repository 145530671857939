<script setup>
import {
  computed, defineProps, onMounted, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import formatDecimal from '../../utils/formatUtils';
import EstimatedAcorHeader from '../dashboard/table/EstimatedAcorHeader.vue';
import A2CAClient from '../../api/a2caAuthorizedClient';
import MetricCard from './MetricCard';
import { formatTargetingType } from '../../utils/reports/targetReportUtils';

dayjs.extend(duration);
const props = defineProps({
  selectedProfile: {
    type: Object,
    required: true,
  },
});
const loading = ref(true);
const metrics = ref({});
const historyEntries = ref([]);
const totalBidRuleActions = ref(0);

const savedtime = computed(() => {
  const totalDurationMinutes = 45 * totalBidRuleActions.value;
  const totalDuration = dayjs.duration(totalDurationMinutes, 'seconds');

  return `${totalDuration.hours()} hours and ${totalDuration.minutes()} minutes`;
});

const historyEntriesFormatted = computed(() => historyEntries.value.map((entry) => ({
  targetName: formatTargetingType(entry.target),
  state: entry.target.state,
  campaign: entry.campaign,
  estimatedAcor: formatDecimal(entry.entries[0].executionData?.trueAcos, 2),
  originalBid: entry.entries?.[0]?.change?.originalBid,
  finalBid: entry.entries?.[0]?.change?.finalBid,
})));
const route = useRoute();

const fetch = async () => {
  const client = new A2CAClient(route.query);
  const [aggregations, history] = await Promise.all([client.getSummary(
    props.selectedProfile.profileId,
    props.selectedProfile.region,
  ), client.getLatestBidRuleHistory({
    advertisingScopeId: props.selectedProfile.profileId,
    region: props.selectedProfile.region,
    page: { page: 0, size: 10 },
    date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
  })]);

  historyEntries.value = history.content;
  totalBidRuleActions.value = history.totalElements;
  const { currentAggregation, previousAggregation } = aggregations;

  metrics.value = {
    reads: {
      value: formatDecimal(currentAggregation.kindleEditionNormalizedPagesRead14d, 0),
      delta: currentAggregation.kindleEditionNormalizedPagesRead14d - previousAggregation.kindleEditionNormalizedPagesRead14d,
    },
    acor: {
      value: formatDecimal(currentAggregation.trueAcos, 2),
      delta: currentAggregation.trueAcos - previousAggregation.trueAcos,
    },
    adRevenue: {
      value: formatDecimal(currentAggregation.adjustedRevenue, 2),
      delta: currentAggregation.adjustedRevenue - previousAggregation.adjustedRevenue,
    },
    adSpend: {
      value: formatDecimal(currentAggregation.cost, 2),
      delta: currentAggregation.cost - previousAggregation.cost,
    },
    orders: {
      value: formatDecimal(currentAggregation.purchases14d, 0),
      delta: currentAggregation.purchases14d - previousAggregation.purchases14d,
    },
  };
  loading.value = false;
};

onMounted(() => {
  fetch();
});
</script>

<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <section
    v-else
    class="h-full"
  >
    <Card class="performance">
      <template #title>
        Your performance last month
      </template>
      <template #content>
        <article class="flex justify-content-around">
          <MetricCard
            name="Orders"
            :value="metrics.orders.value"
            :delta="metrics.orders.delta"
          />
          <MetricCard
            name="Reads"
            :value="metrics.reads.value"
            :delta="metrics.reads.delta"
          />
          <MetricCard
            name="Adj. Revenue"
            :value="metrics.adRevenue.value"
            :delta="metrics.adRevenue.delta"
            preffix="$"
          />
          <MetricCard
            name="Ad. Spend"
            :value="metrics.adSpend.value"
            :delta="metrics.adSpend.delta"
            preffix="$"
          />
          <MetricCard
            name="Est. ACOR"
            :value="metrics.acor.value"
            :delta="metrics.acor.delta"
            suffix="%"
          />
        </article>
        <Message
          severity="success"
          class="mb-0 py-0 bid-rule-saving"
          :closable="false"
        >
          In the past month, BooksFlyer has performed <strong>{{ formatDecimal(totalBidRuleActions, 0) }} bid changes</strong> in your account.
          This equals to approximately <strong>{{ savedtime }}</strong> of your time.
          <span
            v-tooltip.left="'The time-saving estimate is based on benchmarking different users and may not precisely reflect your individual experience.'"
            class="pi pi-info-circle mx-1"
          />
        </Message>
      </template>
    </Card>
    <Card class="mt-3 h-full optimizations">
      <template #title>
        Latest optimization actions
      </template>
      <template #content>
        <DataTable
          :value="historyEntriesFormatted"
          class="scroll-gradient"
          size="small"
          scrollable
          scroll-height="230px"
          scroll-direction="vertical"
        >
          <Column
            field="targetName"
            header="Target/Keyword"
            class="w-2"
          >
            <template #body="slotProps">
              <div
                class="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                style="width:200px"
              >
                {{ slotProps.data.targetName }}
              </div>
            </template>
          </Column>
          <Column
            field="campaign.name"
            header="Campaign"
            class="w-3"
          >
            <template #body="slotProps">
              <div
                class="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                style="width:200px"
              >
                {{ slotProps.data.campaign.name }}
              </div>
            </template>
          </Column>
          <Column
            field="target.state"
            header="Status"
          >
            <template #body="slotProps">
              <span :class="'campaign-badge status-' + slotProps.data.state">{{ slotProps.data.state }}</span>
            </template>
          </Column>
          <Column
            field="estimatedAcor"
            header="Bid changes"
          >
            <template #body="slotProps">
              {{ slotProps.data.originalBid }}  <i class="pi pi-arrow-right px-2" />  {{ slotProps.data.finalBid }}
            </template>
          </Column>
          <Column
            field="estimatedAcor"
            class="justify-content-center"
          >
            <template #header>
              <EstimatedAcorHeader />
            </template>
            <template #body="slotProps">
              {{ `${slotProps.data.estimatedAcor }%` }}
            </template>
          </Column>
        </DataTable>
        <div class="flex justify-content-center mt-3">
          <router-link
            to="/bidrules/history"
            class="font-bold"
          >
            See more
          </router-link>
        </div>
      </template>
    </Card>
  </section>
</template>

<style lang="scss">
.scroll-gradient::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  background: linear-gradient(rgba(255, 255, 255, 0.001), white);
}
.optimizations .p-card-body, .performance .p-card-body {
  padding-bottom: 0 !important;
}
.campaign-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-manual,
  &.status-ENABLED {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-PAUSED {
    background: #feedaf;
    color: #8a5340;
  }

  &.status-auto {
    background: #b3e5fc;
    color: #23547b;
  }

  &.status-ARCHIVED {
    background: #ffd8b2;
    color: #805b36;
  }
}

.bid-rule-saving .p-message-wrapper {
  padding: 1rem !important;
}
</style>
