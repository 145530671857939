class PlacementType {
  constructor(id, label) {
    this.id = id;
    this.label = label;
  }
}

export const PlacementTypeIds = {
  PLACEMENT_TOP: 'placementTop',
  PLACEMENT_PRODUCT_PAGE: 'placementProductPage',
};

export const PlacementTypes = [
  new PlacementType(PlacementTypeIds.PLACEMENT_TOP, 'Top of Search on-Amazon'),
  new PlacementType(PlacementTypeIds.PLACEMENT_PRODUCT_PAGE, 'Detail Page on-Amazon'),
  new PlacementType(null, 'Other on-Amazon'),
];

export const findPlacementByLabel = (label) => PlacementTypes.find((placement) => placement.label === label);
