import { getConfig } from '../utils/apiUtils';

export default class A2CAClient {
  constructor(instance) {
    if (instance) {
      this.agent = instance;
    } else {
      const defaultInstance = getConfig();
      this.agent = defaultInstance;
    }
  }

  async logIn(credentials) {
    const response = await this.agent.post('auth/login', credentials);
    return response.data;
  }

  async accountExists(email) {
    const response = await this.agent.get('auth/check', { params: { email } });
    return response.data;
  }

  async getAuthorizationUrl(userId) {
    const response = await this.agent.get('lwa/auth_url', {
      params: { userId },
    });
    return response.data;
  }

  async registerAccount(name, email, password, country, invitationCode, userCategory, bookGenres) {
    const response = await this.agent.post('auth/signup', {
      name,
      email,
      password,
      country,
      invitationCode,
      additionalFields: {
        userCategory,
        bookGenres,
      },
    });
    return response.data;
  }

  async requestPasswordReset(email) {
    const response = await this.agent.post('auth/password/reset/request', {
      email,
    });
    return response.data;
  }

  async performPasswordReset(email, code, password) {
    const response = await this.agent.post('auth/password/reset/perform', {
      email,
      resetCode: code,
      newPassword: password,
    });
    return response.data;
  }
}
