const BID_RULE_CREATION_SUCCESS = {
  summary: 'Bid rule created',
  detail: 'Your bid rule has been created',
};

const BID_RULE_CREATION_ERROR = {
  summary: 'Could not create bid rule',
  detail: 'At least one campaign must be selected.',
};

const BID_RULE_PREDICATE_CREATION_ERROR = {
  summary: 'Could not create bid rule',
  detail: 'Bid rule predicate values cannot be empty.',
};

const BID_RULE_RECURRENCE_CREATION_ERROR = {
  summary: 'Could not create bid rule',
  detail: 'Weekly recurrence needs a day of the week',
};

const BID_RULE_DELETION_SUCCESS = {
  summary: 'Bid rule deleted',
  detail: 'The bid rule has been deleted.',
};

const BID_RULE_UPDATE_SUCCESS = {
  summary: 'Bid rule updated',
  detail: 'Your bid rule has been updated successfully',
};

const BID_RULE_UPDATE_ERROR = {
  summary: 'Could not update bid rule',
  detail: 'At least one campaign must be selected.',
};

const FETCH_DATA_CAMPAIGNS = {
  summary: 'Some campaign data is not downloaded',
  detail: 'The campaign data of some days in the selected range is not downloaded. To start downloading all the data click on the "Download reports" button.',
};

const FETCH_DATA_AD_GROUP = {
  summary: 'Some ad group data is not downloaded',
  detail: 'The ad group data of some days in the selected range is not downloaded. To start downloading all the data click on the "Download reports" button.',
};

const FETCH_DATA_KEYWORDS = {
  summary: 'Some target data is not downloaded',
  detail: 'The target data of some days in the selected range is not downloaded. To start downloading all the data click on the "Download reports" button.',
};

const UPDATE_SETTINGS = {
  summary: 'Settings updated',
  detail: 'Profile settings updated',
};

const METRIC_CREATION_SUCCESS = {
  summary: 'Metric created',
  detail: 'Your metric has been created',
};

const METRIC_CREATION_ERROR = {
  summary: 'Could not create metric group',
  detail: 'It is mandatory to fill all fields',
};

const METRIC_CREATION_NAME_ERROR = {
  summary: 'Could not create metric group',
  detail: 'It is mandatory to fill all fields',
};

const METRIC_CREATION_METRICS_ERROR = {
  summary: 'Could not create metric group',
  detail: 'It is mandatory to fill all fields',
};

const METRIC_MINMAX_ERROR = {
  summary: 'Could not create metric group',
  detail: 'Minimum bid cannot be greater than maximum bid',
};

const METRIC_DELETION_SUCCESS = {
  summary: 'Metric deleted',
  detail: 'The metric has been deleted.',
};

const METRIC_UPDATE_SUCCESS = {
  summary: 'Metric updated',
  detail: 'The metric has been updated successfully',
};

const METRIC_UPDATE_ERROR = {
  summary: 'Could not update metric',
  detail: 'All fields are mandatory.',
};

export {
  BID_RULE_CREATION_SUCCESS, BID_RULE_CREATION_ERROR, BID_RULE_PREDICATE_CREATION_ERROR, BID_RULE_DELETION_SUCCESS, BID_RULE_UPDATE_ERROR, BID_RULE_UPDATE_SUCCESS, FETCH_DATA_AD_GROUP, FETCH_DATA_KEYWORDS, FETCH_DATA_CAMPAIGNS, UPDATE_SETTINGS, METRIC_CREATION_SUCCESS, METRIC_CREATION_ERROR, METRIC_CREATION_NAME_ERROR, METRIC_CREATION_METRICS_ERROR, METRIC_DELETION_SUCCESS, METRIC_UPDATE_SUCCESS, METRIC_UPDATE_ERROR, BID_RULE_RECURRENCE_CREATION_ERROR, METRIC_MINMAX_ERROR,
};
