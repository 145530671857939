<template>
  <Toast />
  <div>
    <router-view />
  </div>
</template>

<script></script>

<style lang="scss">
@import "./App.scss";
</style>
