<template>
  <div
    class="surface-50 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  >
    <div
      class="grid justify-content-center p-2 lg:p-0"
      style="min-width: 80%"
    >
      <div class="col-12 mt-5 xl:mt-0 text-center">
        <img
          src="/images/logo.png"
          alt="BooksFlyer logo"
          class="mb-5"
          style="height: 3rem;"
        >
      </div>
      <div
        class="col-12 xl:col-6"
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(
            180deg,
            var(--primary-color),
            rgba(33, 150, 243, 0) 30%
          );
        "
      >
        <div
          class="surface-section h-full w-full m-0 py-7 px-4"
          style="border-radius: 53px"
        >
          <div class="text-center mb-5">
            <span class="text-900 text-3xl font-medium mb-3">Sign up</span>
          </div>

          <div class="w-full md:w-10 mx-auto">
            <div class="mb-3">
              <label
                for="username"
                class="block text-900 text-xl font-medium mb-2"
              >Name</label>
              <InputText
                id="username"
                v-model="values.name"
                type="text"
                :class="['w-full', 'mb-1', !!errors.name && 'p-invalid']"
                placeholder="Name"
                style="padding: 1rem"
                @blur="validate('name')"
                @keypress="validate('name')"
              />
              <small v-if="!!errors.name">
                {{ errors.name }}
              </small>
            </div>
            <div class="mb-3">
              <label
                for="email1"
                class="block text-900 text-xl font-medium mb-2"
              >Email</label>
              <InputText
                id="email1"
                v-model="values.email"
                type="text"
                :class="['w-full', 'mb-1', !!errors.email && 'p-invalid']"
                placeholder="Email"
                style="padding: 1rem"
                @blur="validate('email')"
                @keypress="validate('email')"
              />
              <small v-if="!!errors.email">
                {{ errors.email }}
              </small>
            </div>

            <div class="mb-3">
              <label
                for="country"
                class="block text-900 text-xl font-medium mb-2"
              >Country of Residence</label>
              <div :class="['p-dropdown', 'p-component', 'p-inputwrapper', 'w-full', 'mb-1', !!errors.country && 'p-invalid']">
                <country-select
                  v-model="values.country"
                  :disable-placeholder="true"
                  :class="['p-dropdown-label', 'p-inputtext', 'p-placeholder']"
                  :country="values.country"
                  :autocomplete="true"
                  placeholder="Select a country of residence"
                  @blur="validate('country')"
                  @keypress="validate('country')"
                />
              </div>

              <small v-if="!!errors.country">
                {{ errors.country }}
              </small>
            </div>

            <div class="mb-3">
              <label
                for="password1"
                class="block text-900 font-medium text-xl mb-2"
              >Password</label>
              <Password
                id="password1"
                v-model="values.password"
                placeholder="Password"
                :toggle-mask="true"
                :class="['w-full', 'mb-1', !!errors.password && 'p-invalid']"
                input-class="w-full"
                input-style="padding:1rem"
                @blur="validate('password')"
                @keypress="validate('password')"
              />
              <small
                v-if="!!errors.password"
                class="block"
              >
                {{ errors.password }}
              </small>
            </div>
            <div class="mb-3">
              <label
                for="password2"
                class="block text-900 font-medium text-xl mb-2"
              >Repeat Password</label>
              <Password
                id="password2"
                v-model="values.password2"
                placeholder="Repeat Password"
                :toggle-mask="true"
                :class="['w-full', 'mb-1', !!errors.password && 'p-invalid']"
                input-class="w-full"
                input-style="padding:1rem"
                @blur="validate('password2')"
                @keypress="validate('password2')"
              />
              <small
                v-if="!!errors.password2"
                class="block"
              >
                {{ errors.password2 }}
              </small>
            </div>
            <div
              v-show="showInvitationCode()"
              class="mb-3"
            >
              <label
                for="username"
                class="block text-900 text-xl font-medium mb-2"
              >Invitation Code</label>
              <InputText
                id="invitationCode"
                v-model="values.invitationCode"
                type="text"
                :class="['w-full', 'mb-1', !!errors.invitationCode && 'p-invalid']"
                placeholder="Invitation Code"
                style="padding: 1rem"
                @blur="validate('invitationCode')"
                @keypress="validate('invitationCode')"
              />
              <small v-if="!!errors.invitationCode">
                {{ errors.invitationCode }}
              </small>
            </div>

            <div class="mb-3">
              <label
                for="userCategoryDropdown"
                class="block text-900 text-xl font-medium mb-2"
              >Please, select the option that corresponds to you. I am a:</label>
              <div :class="[ !!errors.userCategoryDropdown && 'p-invalid']">
                <Dropdown
                  v-model="values.userCategoryDropdown"
                  :disable-placeholder="true"
                  :class="[' w-full', !!errors.userCategoryDropdown && 'p-invalid']"
                  :autocomplete="true"
                  :options="getSignupCategories()"
                  placeholder="Select a category..."
                  @blur="validate('userCategoryDropdown')"
                  @keypress="validate('userCategoryDropdown')"
                />
              </div>

              <small v-if="!!errors.userCategoryDropdown">
                {{ errors.userCategoryDropdown }}
              </small>
            </div>
            <div
              v-if="values.userCategoryDropdown === getCategoryOther()"
              class="mb-3"
            >
              <InputText
                id="username"
                v-model="values.userCategoryOther"
                type="text"
                :class="['w-full', 'mb-1', !!errors.userCategoryOther && 'p-invalid']"
                placeholder="Please specify your choice"
                style="padding: 1rem"
                @blur="validate('userCategoryOther')"
                @keypress="validate('userCategoryOther')"
              />
              <small v-if="!!errors.userCategoryOther">
                {{ errors.userCategoryOther }}
              </small>
            </div>

            <div class="mb-3">
              <label
                for="bookGenresDropdown"
                class="block text-900 text-xl font-medium mb-2"
              >Please select the genre of the books you advertise. You may select up to two different genres.</label>
              <div :class="[ !!errors.bookGenresDropdown && 'p-invalid']">
                <MultiSelect
                  v-model="values.bookGenresDropdown"
                  :disable-placeholder="true"
                  :class="[' w-full', !!errors.bookGenresDropdown && 'p-invalid']"
                  :autocomplete="true"
                  :selection-limit="2"
                  :show-toggle-all="false"
                  :options="getBookGenres()"
                  placeholder="Select a book genre..."
                  @blur="validate('bookGenresDropdown')"
                  @keypress="validate('bookGenresDropdown')"
                  @change="validate('bookGenresDropdown')"
                />
              </div>

              <small v-if="!!errors.bookGenresDropdown">
                {{ errors.bookGenresDropdown }}
              </small>
            </div>
            <div
              v-if="values.bookGenresDropdown.includes(getBookGenreOther())"
              class="mb-3"
            >
              <InputText
                id="username"
                v-model="values.bookGenresOther"
                type="text"
                :class="['w-full', 'mb-1', !!errors.bookGenresOther && 'p-invalid']"
                placeholder="Please specify your choice"
                style="padding: 1rem"
                @blur="validate('bookGenresOther')"
                @keypress="validate('bookGenresOther')"
              />
              <small v-if="!!errors.bookGenresOther">
                {{ errors.bookGenresOther }}
              </small>
            </div>

            <div class="mb-2 flex flex-wrap gap-3">
              <div class="flex align-items-center">
                <Checkbox
                  v-model="values.termsAndConditionsAccepted"
                  :binary="true"
                  @blur="validate('termsAndConditionsAccepted')"
                  @keypress="validate('termsAndConditionsAccepted')"
                />
                <label class="ml-2">I accept the <a
                  class="terms-and-conditions"
                  href="https://www.booksflyer.com/terms-and-conditions"
                  target="_blank"
                >Terms & Conditions</a> of using this software.</label>
              </div>
            </div>
            <small
              v-if="!!errors.termsAndConditionsAccepted"
              class="mb-3 block error-label"
            >
              {{ errors.termsAndConditionsAccepted }}
            </small>

            <Button
              label="Sign up"
              class="w-full p-3 text-xl"
              :disabled="loading"
              @click="signupHandler"
            />
            <p class="text-center pt-3">
              Already have an account?
              <router-link
                class="font-bold"
                to="/login"
              >
                Log In
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import {
  object, string, ref, bool, array,
} from 'yup';
import { mapMutations } from 'vuex';
import A2CAClient from '../api/a2caClient';
import {
  SIGNUP_CATEGORIES, CATEGORY_OTHER, BOOK_GENRES, BOOK_GENRE_OTHER,
} from '../constants/signupConstants';

const invitationCodeSignupSchema = object().shape({
  name: string().min(2).required(),
  email: string().required().email(),
  password: string().min(4).required(),
  password2: string().oneOf([ref('password'), null], 'Passwords do not match.'),
  country: string().required('Country of residence is required'),
  invitationCode: string().required(),
  termsAndConditionsAccepted: bool().oneOf([true], 'The terms and conditions must be accepted'),
  userCategoryDropdown: string().required('The user category is required'),
  userCategoryOther: string().when('userCategoryDropdown', {
    is: CATEGORY_OTHER,
    then: string().required('The user category is required'),
  }),
  bookGenresDropdown: array().of(string()).min(1, 'At least one genre must be selected').required('The book genres are required'),
  bookGenresOther: string().when('bookGenresDropdown', {
    is: (bookGenres) => bookGenres.includes(BOOK_GENRE_OTHER),
    then: string().required('The book genre is required'),
  }),
});

const nonInvitationCodeSignupSchema = object().shape({
  name: string().min(2).required(),
  email: string().required().email(),
  password: string().min(4).required(),
  password2: string().oneOf([ref('password'), null], 'Passwords do not match.'),
  country: string().required('Country of residence is required'),
  termsAndConditionsAccepted: bool().oneOf([true], 'The terms and conditions must be accepted'),
  userCategoryDropdown: string().required('The user category is required'),
  userCategoryOther: string().when('userCategoryDropdown', {
    is: CATEGORY_OTHER,
    then: string().required('The user category is required'),
  }),
  bookGenresDropdown: array().of(string()).min(1, 'At least one genre must be selected').required('The book genres are required'),
  bookGenresOther: string().when('bookGenresDropdown', {
    is: (bookGenres) => bookGenres.includes(BOOK_GENRE_OTHER),
    then: string().required('The book genre is required'),
  }),
});

const client = new A2CAClient();

export default {
  data() {
    return {
      values: {
        name: '',
        email: this.$route.query.email,
        password: '',
        password2: '',
        country: '',
        invitationCode: this.$route.query.code,
        termsAndConditionsAccepted: false,
        userCategoryOther: '',
        userCategoryDropdown: '',
        bookGenresDropdown: [],
        bookGenresOther: '',
      },
      errors: {
        name: '',
        email: '',
        password: '',
        password2: '',
        country: '',
        invitationCode: '',
        termsAndConditionsAccepted: '',
        bookGenresDropdown: '',
        bookGenresOther: '',
      },
      loading: false,
    };
  },
  computed: {
    // eslint-disable-next-line vue/no-unused-properties
    logoColor() {
      if (this.$appState.darkTheme) return 'white';
      return 'dark';
    },
  },
  methods: {
    showInvitationCode() {
      console.debug(process.env.VUE_APP_REQUIRE_INVITATION_CODE);
      return process.env.VUE_APP_REQUIRE_INVITATION_CODE === 'true';
    },
    getSignupCategories() {
      return SIGNUP_CATEGORIES;
    },
    getCategoryOther() {
      return CATEGORY_OTHER;
    },
    getBookGenres() {
      return BOOK_GENRES;
    },
    getBookGenreOther() {
      return BOOK_GENRE_OTHER;
    },
    signupHandler() {
      (process.env.VUE_APP_REQUIRE_INVITATION_CODE === 'true' ? invitationCodeSignupSchema : nonInvitationCodeSignupSchema)
        .validate(this.values, { abortEarly: false })
        .then(async () => {
          this.errors = {};
          this.loading = true;
          try {
            const accountExists = await this.fetchAccountCheck();
            if (accountExists) {
              this.errors.email = 'Account already exists';
              this.loading = false;
              return;
            }
            const crendetials = await this.fetchRegisterAccount(
              this.values.name,
              this.values.email,
              this.values.password,
              this.values.country,
              this.values.invitationCode,
              this.values.userCategoryDropdown === CATEGORY_OTHER ? this.values.userCategoryOther : this.values.userCategoryDropdown,
              this.values.bookGenresDropdown.includes(BOOK_GENRE_OTHER) ? [...this.values.bookGenresDropdown.filter((genre) => genre !== BOOK_GENRE_OTHER), this.values.bookGenresOther] : this.values.bookGenresDropdown,
            );

            if (crendetials?.type) {
              switch (crendetials?.type) {
                case 'ACCOUNT_EXISTS':
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Signup Error',
                    detail: 'Account already exists',
                    life: 3000,
                  });
                  break;
                case 'INVALID_CODE':
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Signup Error',
                    detail: 'Invitation code is not valid',
                    life: 3000,
                  });
                  break;
                case 'EXPIRED_CODE':
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Signup Error',
                    detail: 'Invitation code has already expired',
                    life: 3000,
                  });
                  break;
                default:
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Signup Error',
                    detail: crendetials.type,
                    life: 3000,
                  });
              }
            } else {
              this.login(crendetials.token);
              // redirect to dashboard
              this.$router.push({ path: '/' });
            }
          } catch (err) {
            this.$toast.add({
              severity: 'error',
              summary: 'Signup Error',
              detail: err,
              life: 3000,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors[error.path] = error.message;
          });
        });
    },
    validate(field) {
      (process.env.VUE_APP_REQUIRE_INVITATION_CODE === 'true' ? invitationCodeSignupSchema : nonInvitationCodeSignupSchema)
        .validateAt(field, this.values, { abortEarly: true })
        .then(() => {
          this.errors[field] = '';
        })
        .catch((err) => {
          this.errors[field] = err.message;
        });
    },
    async fetchAccountCheck() {
      const response = await client.accountExists(this.values.email);
      return response;
    },
    async fetchRegisterAccount(name, email, password, country, invitationCode, userCategory, bookGenres) {
      const response = await client.registerAccount(name, email, password, country, invitationCode, userCategory, bookGenres);
      return response;
    },
    ...mapMutations(['login']),
  },
};
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}

.terms-and-conditions {
  color: #6465F1;
}

.error-label {
  color: #e24c4c;;
}

</style>
