import { accumulateGroupedKpi } from './reportUtils';

const aggregateByTarget = (reportsContent, targets) => {
  const aggregatedTargets = reportsContent.reduce((acc, obj) => {
    const key = obj.targetId;
    const target = targets.find((k) => k.targetId === obj.targetId);
    return accumulateGroupedKpi(acc, obj, key, target);
  }, {});
  return aggregatedTargets;
};

const formatTargetingType = (target) => {
  if (target?.expression?.[0]?.type === 'QUERY_HIGH_REL_MATCHES') {
    return 'close-match';
  }
  if (target?.expression?.[0]?.type === 'QUERY_BROAD_REL_MATCHES') {
    return 'loose-match';
  }
  if (target?.expression?.[0]?.type === 'ASIN_ACCESSORY_RELATED') {
    return 'complements';
  }
  if (target?.expression?.[0]?.type === 'ASIN_SUBSTITUTE_RELATED') {
    return 'substitutes';
  }

  return target.keywordText ?? target.resolvedExpression?.[0].value ?? JSON.stringify(target);
};

const mergeApiTargets = (keywordData, apitargets) => {
  const mergedApitargets = [];
  apitargets.forEach((apiTarget) => {
    const currentTargetData = keywordData.find((data) => data.keywordId === apiTarget.targetId);
    if (currentTargetData) {
      mergedApitargets.push({ ...currentTargetData, ...apiTarget });
    } else if (apiTarget.state === 'ENABLED') {
      mergedApitargets.push({
        clicks: 0,
        cost: 0,
        impressions: 0,
        sales14d: 0,
        purchases14d: 0,
        kindleEditionNormalizedPagesRoyalties14d: 0,
        targeting: formatTargetingType(apiTarget),
        ...apiTarget,
      });
    }
  });

  return mergedApitargets;
};

export {
  aggregateByTarget, mergeApiTargets, formatTargetingType,
};
