import { getProfileSelectedCampaignsKey } from '../constants/storageConstants';

export default {
  /**
   *
   * selectedCampaigns: selected campaings for campaigns chart
   * selectedDateRange: selected date range for calendar
   */
  state: () => ({
    selectedCampaigns: null,
    selectedDateRange: null,
  }),
  getters: {
    selectedCampaigns(state, profileId, useSession = false) {
      let storage;
      if (useSession) {
        storage = sessionStorage;
      } else {
        storage = localStorage;
      }
      const selectedCampaigns = storage.getItem(getProfileSelectedCampaignsKey(profileId));
      return JSON.parse(selectedCampaigns);
    },
  },
  mutations: {
    setSelectedCampaigns(state, campaigns, useSession = false) {
      const { selectedCampaigns } = campaigns;
      const { profileId } = campaigns;
      let storage;
      if (useSession) {
        storage = sessionStorage;
      } else {
        storage = localStorage;
      }

      state.selectedCampaigns = selectedCampaigns;
      storage.setItem(getProfileSelectedCampaignsKey(profileId), JSON.stringify(selectedCampaigns));
    },
  },
};
