<template>
  <div class="text-center pb-5">
    <SelectButton
      v-model="selectedKpi"
      :options="kpiOptions"
      option-label="selectedKpi"
      option-disabled="disabled"
      multiple
    >
      <template #option="slotProps">
        <div>
          <h3
            v-if="slotProps.option.id === 4"
            class="text-xl"
          >
            <!--Adjusted Revenue-->
            {{ slotProps.option.name }}  <span
              v-tooltip="'Sum of Sales Royalties + KENP Royalties multiplied by the Revenue Multiplier.'"
              class="pi pi-info-circle mx-1"
            />
          </h3>
          <h3
            v-else-if="slotProps.option.id === 2"
            class="text-xl"
          >
            <!--Estimated ACOR-->
            {{ slotProps.option.name }} <span
              v-tooltip="'Estimated Advertising Cost of Revenue. Formula: Ad Spend / Adjusted Revenue.'"
              class="pi pi-info-circle mx-1"
            />
          </h3>
          <h3
            v-else
            class="text-xl"
          >
            {{ slotProps.option.name }}
          </h3>
          <p class="font-semibold">
            {{
              kpiAggregation[slotProps.option.value]
                ? formatKpi(
                  slotProps.option.value,
                  kpiAggregation[slotProps.option.value]
                )
                : "0"
            }}
          </p>
        </div>
      </template>
      >
    </SelectButton>
  </div>
  <div class="flex justify-content-center">
    <Chart
      type="line"
      :data="generalChartData"
      :options="generalChartOptions"
      :width="800"
      :height="400"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { KPIConstants } from '../../../constants/kpiConstants';
import { currencySymbolFromCode } from '../../../utils/formatUtils';

const DEFAULT_KPI_OPTIONS = [
  {
    id: 5, name: 'Impressions', value: KPIConstants.IMPRESSIONS, disabled: false,
  },
  {
    id: 3, name: 'Clicks', value: KPIConstants.CLICKS, disabled: false,
  },
  {
    id: 1, name: 'Total Ad Spend', value: KPIConstants.AD_SPEND, disabled: false,
  },
  {
    id: 4, name: 'Adjusted Revenue', value: KPIConstants.ADJUSTED_REVENUE, disabled: false,
  },
  {
    id: 2, name: 'Estimated ACOR', value: KPIConstants.ACOS, disabled: false,
  },

];

const Y_AXIS_DATASETS = [
  {
    id: 'y',
    color: '#798ce0',
  }, {
    id: 'y1',
    color: '#61a064',
  },
];

export default {
  props: {
    reports: {
      type: Object,
      required: true,
    },
    kpiAggregation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedKpi: null,
      kpiOptions: DEFAULT_KPI_OPTIONS,
      lastSelectedKpiOption: null,
      generalChartData: {
        labels: Object.keys(this.reports).map((reportDate) => dayjs(reportDate).format('YYYY-MM-DD')),
        datasets: [],
      },
      generalChartOptions: {
        responsive: false,
        stacked: false,
        plugins: {
          legend: {
            labels: {
              color: '#495057',
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: '#495057',
            },
            grid: {
              color: '#ebedef',
            },
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            ticks: {
              color: '#798ce0',
            },
            grid: {
              color: '#ebedef',
            },
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            ticks: {
              color: '#61a064',
            },
            grid: {
              drawOnChartArea: false,
              color: '#ebedef',
            },
          },
        },
      },
    };
  },
  watch: {
    selectedKpi: {
      handler(val, oldVal) {
        this.limitSelectButtonSize(val, oldVal);
        // Map datasets from KPIs
        this.generalChartData.datasets = this.selectedKpi.map((kpi, idx) => this.buildDataset(kpi, Y_AXIS_DATASETS[idx].id, Y_AXIS_DATASETS[idx].color));
        this.selectedKpi.forEach((kpi, idx) => {
          const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            useGrouping: false,
          });
          if (kpi.value === KPIConstants.ACOS) {
            this.generalChartOptions.scales[Y_AXIS_DATASETS[idx].id].ticks.callback = (value) => `${formatter.format(value)}%`;
          } else {
            this.generalChartOptions.scales[Y_AXIS_DATASETS[idx].id].ticks.callback = (value) => formatter.format(value);
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    // default selected options
    // eslint-disable-next-line prefer-destructuring
    this.lastSelectedKpiOption = this.kpiOptions[1];
    this.selectedKpi = this.kpiOptions.slice(0, 2);
  },
  methods: {
    /**
   *
   * Builds a chart dataset object based on a KPI object.
   *
   * The reports data will be used in the dataset building process.
   *
   * @param {Object} kpi The KPI object used to build the dataset.
   * @param {string} yAxisID The Y axis ID of the dataset.
   * @param {string} axisColor The axis color of the dataset.
   *
   * @returns {Object} The built dataset object.
   */
    buildDataset(kpi, yAxisID, axisColor) {
      const reportData = Object.values(this.reports);

      // Get all specific kpi data of all report's days
      const data = reportData.reduce((prev, current, index) => {
        prev.push(reportData[index][kpi.value]);
        return prev;
      }, []);

      return {
        label: kpi.name,
        fill: false,
        borderColor: axisColor,
        yAxisID,
        tension: 0.4,
        data,
      };
    },
    /**
   *
   * Sets the kpiOptions data based on the number of currently selected options
   *
   * If a third option is selected, the last selected option will be disabled.
   *
   * @param {array} selectedKpi The array of currently selected KPI objects
   */
    limitSelectButtonSize(selectedKpi, oldSelectedKpi) {
      if (oldSelectedKpi === null) {
        return;
      }

      const newKpi = selectedKpi.find((kpi) => oldSelectedKpi.find((oldKpi) => oldKpi.id !== kpi.id) !== undefined);

      if (selectedKpi.length > 2) {
        this.selectedKpi = this.selectedKpi.filter((kpi) => kpi.id !== this.lastSelectedKpiOption.id);
      }
      if (selectedKpi.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        this.lastSelectedKpiOption = this.selectedKpi[0];
      } else {
        this.lastSelectedKpiOption = newKpi;
      }
    },
    formatKpi(kpi, value) {
      if (kpi === KPIConstants.ADJUSTED_REVENUE || kpi === KPIConstants.AD_SPEND) {
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return `${currencySymbolFromCode(this.$store.state.auth.selectedProfile.currencyCode)}${formatter.format(value)}`;
      }

      if (kpi === KPIConstants.ACOS) {
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return `${formatter.format(value)}%`;
      }

      return value;
    },
  },
};
</script>
