<template>
  <span hidden="hidden">
    {{ key }}
  </span>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <template v-else>
    <BidRules
      v-if="initialBidRuleSize > 0 || setup === '0'"
      :selected-profile="selectedProfile"
      :campaigns="campaigns"
      :bid-rules="bidRules"
      :bid-rule-templates="bidRuleTemplates"
      @create-bid-rule="createBidRuleHandler"
      @delete-bid-rule="deleteBidRuleHandler"
    />
    <BidRuleSetupSelector
      v-else
      :selected-profile="selectedProfile"
      :campaigns="campaigns"
      @generate-bid-rules="generateBidRulesHandler"
    />
  </template>
</template>

<script>
import BidRules from './list/BidRules.vue';
import BidRuleSetupSelector from './setup/BidRuleSetupSelector.vue';
import A2CAClient from '../../../api/a2caAuthorizedClient';

export default {
  components: { BidRules, BidRuleSetupSelector },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    key: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['clear-selected-profile', 'change-menu-layout'],
  data() {
    return {
      campaigns: null,
      bidRules: null,
      bidRuleTemplates: null,
      loading: true,
      initialBidRuleSize: null,
      setup: null,
    };
  },
  async mounted() {
    this.loading = true;
    const client = new A2CAClient(this.query);
    const campaignsRequest = client.getCampaignsWithMetricData(
      this.selectedProfile.profileId,
      this.selectedProfile.region,
      'ENABLED',
      true,
    );

    const bidRulesRequest = client.getBidRules(this.selectedProfile.profileId);

    const bidRuleTemplatesRequest = client.getBidRuleTemplates();

    const [campaigns, bidRuleList, bidRuleTemplates] = await Promise.all([campaignsRequest, bidRulesRequest, bidRuleTemplatesRequest]);
    this.campaigns = campaigns;
    this.bidRules = bidRuleList.bidRules;
    this.initialBidRuleSize = bidRuleList.bidRules.length;
    this.bidRuleTemplates = bidRuleTemplates;
    this.maxBid = bidRuleList.maxBid;

    // Get setup search param. After getting it, delete it from the params and remove it from the URL in order to avoid propagation
    const searchParams = new URLSearchParams(document.location.search);
    console.warn(searchParams);
    this.setup = searchParams.get('setup');
    searchParams.delete('setup');
    window.history.pushState({}, document.title, window.location.pathname);

    this.loading = false;
  },
  methods: {
    createBidRuleHandler(createdBidRule) {
      this.bidRules.push(createdBidRule);
    },
    deleteBidRuleHandler(bidRuleId) {
      this.bidRules = this.bidRules.filter((rule) => rule.id !== bidRuleId);
    },
    generateBidRulesHandler(bidRules) {
      this.bidRules = [...this.bidRules, ...bidRules];
    },
  },
};
</script>
