<template>
  Estimated ACOR
  <span
    v-tooltip="'Estimated Advertising Cost of Revenue. Formula: Ad Spend / Adjusted Revenue.'"
    class="pi pi-info-circle mx-1"
  />
</template>

<script>
export default {};
</script>
