<template>
  <Card>
    <template #title>
      <h2 class="text-2xl">
        Bidding Rule Creation
      </h2>
    </template>
    <template #content>
      <div class="pb-3">
        <label
          for="bidRuleTemplates"
          class="block pb-2 text-base font-semibold"
        >
          Rule Templates
        </label>
        <Dropdown
          id="bidRuleTemplates"
          v-model="currentBidRuleTemplate"
          class="fixed-dropdown"
          :options="bidRuleTemplates"
          option-label="name"
          placeholder="Choose a template"
        />
      </div>
      <BidRuleForm
        :editable="true"
        :initial-value="initialBidRule"
        :campaigns="campaigns"
        @updateForm="updateForm"
      />
    </template>
    <template #footer>
      <Button
        :loading="loading"
        @click="createBidRule"
      >
        Create
      </Button>
    </template>
  </Card>
  <Toast />
</template>
<script>
import { BID_RULE_CREATION_SUCCESS } from '../../../../constants/toastConstants';
import validateForm from '../../../../utils/formUtils';
import BidRuleForm from '../form/BidRuleForm.vue';
import A2CAClient from '../../../../api/a2caAuthorizedClient';
import {
  PREDICATE_OPERANDS, METRIC_OPERANDS, METRICS, VALUE_TYPES, RESULT_TYPES, DAYS_OF_WEEK, RECURRENCE_TYPES,
} from '../../../../constants/bidRulesConstants';
import { LAST_SELECTED_RECURRENCY } from '../../../../constants/storageConstants';
import { formBidRuleToApi, apiBidRuleToForm } from '../../../../utils/bidRuleUtils';

const BID_RULE_TEMPLATE = {
  name: '',
  campaignTargets: [],
  predicateOperand: PREDICATE_OPERANDS[0],
  predicates: [
    {
      operand: METRIC_OPERANDS[0],
      metric: METRICS[0],
      value: 0,
    },
  ],
  result: {
    // eslint-disable-next-line vue/no-unused-properties
    type: RESULT_TYPES[0],
    // eslint-disable-next-line vue/no-unused-properties
    value: {
      value: 0,
      type: VALUE_TYPES[0],
    },
  },
  recurrence: JSON.parse(localStorage.getItem(LAST_SELECTED_RECURRENCY)) ?? {
    // eslint-disable-next-line vue/no-unused-properties
    type: RECURRENCE_TYPES[0],
    // eslint-disable-next-line vue/no-unused-properties
    dayOfWeek: DAYS_OF_WEEK[0],
  },
  allCampaignsSelected: false,
};

export default {
  components: { BidRuleForm },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    bidRuleTemplates: {
      type: Array,
      required: true,
    },

  },
  emits: ['createBidRule'],
  data() {
    return {
      initialBidRule: BID_RULE_TEMPLATE,
      currentBidRule: BID_RULE_TEMPLATE,
      currentBidRuleTemplate: null,
      loading: false,
    };
  },
  watch: {
    currentBidRuleTemplate: {
      handler(val) {
        if (val == null) {
          return;
        }
        const resettedBidRule = { ...JSON.parse(JSON.stringify(apiBidRuleToForm(val))), editId: Math.random() };
        this.initialBidRule = resettedBidRule;
      },
    },
  },
  methods: {
    updateForm(updatedForm) {
      this.currentBidRule = updatedForm;
    },
    async createBidRule() {
      this.loading = true;
      if (!validateForm(this, this.campaigns.length)) {
        return;
      }

      const bidRule = formBidRuleToApi(this.currentBidRule, this.campaigns.length);
      const selectedRecurrence = this.currentBidRule.recurrence;

      const client = new A2CAClient(this.query);
      const createdBidRule = await client.createBidRule(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        bidRule,
      );

      if (createdBidRule?.type) {
        this.$toast.add({
          severity: 'error',
          summary: 'Could not create bid rule',
          detail: 'Make sure all the campaigns in this rule have metrics assigned.',
          life: 3000,
        });
      } else {
        console.debug('Created bid rule', createdBidRule);
        this.$emit('createBidRule', createdBidRule);

        this.$toast.add({
          severity: 'success',
          summary: BID_RULE_CREATION_SUCCESS.summary,
          detail: BID_RULE_CREATION_SUCCESS.detail,
          life: 3000,
        });

        const resettedBidRule = { ...JSON.parse(JSON.stringify(BID_RULE_TEMPLATE)), editId: Math.random() };
        this.initialBidRule = resettedBidRule;
        this.initialBidRule.recurrence = selectedRecurrence;
        this.currentBidRuleTemplate = null;

        localStorage.setItem(LAST_SELECTED_RECURRENCY, JSON.stringify(selectedRecurrence));
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
    .fixed-dropdown {
      width: 20rem;
    }
</style>
