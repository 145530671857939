<template>
  <Card>
    <template #content>
      <BidRuleForm
        :editable="editing"
        :disable-result-type="true"
        :initial-value="initialBidRule"
        :campaigns="campaigns"
        @updateForm="updateForm"
      />
    </template>
    <template #footer>
      <div class="flex">
        <div class="flex">
          <Button
            v-show="!editing"
            :loading="loading"
            class="p-button-danger"
            @click="deleteBidRule"
          >
            Delete
          </Button>
        </div>
        <div class="flex justify-content-end button-gap align-items-center flex-1">
          <p class="m-0 font-italic text-gray-700">
            {{ formatExecutionDate(nextTriggerDate) }}
          </p>
          <Button
            v-show="!editing"
            :loading="loading"
            class="p-button-secondary"
            @click="startEdit"
          >
            Edit
          </Button>
          <Button
            v-show="editing"
            :loading="loading"
            class="p-button-secondary"
            @click="cancelEdit"
          >
            Cancel
          </Button>
          <Button
            v-show="editing"
            :loading="loading"
            @click="updateBidRule"
          >
            Update
          </Button>
        </div>
      </div>
    </template>
  </Card>
  <Toast />
</template>
<script>
import dayjs from 'dayjs';
import {
  BID_RULE_DELETION_SUCCESS, BID_RULE_UPDATE_SUCCESS,
} from '../../../../constants/toastConstants';
import validateForm from '../../../../utils/formUtils';
import BidRuleForm from '../form/BidRuleForm.vue';
import A2CAClient from '../../../../api/a2caAuthorizedClient';
import { formBidRuleToApi } from '../../../../utils/bidRuleUtils';

export default {
  components: { BidRuleForm },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    bidRule: {
      type: Object,
      required: true,
    },
    bidRuleId: {
      type: String,
      required: true,
    },
    nextTriggerDate: {
      type: String,
      required: false,
      default: null,
    },

  },
  emits: ['deleteBidRule'],
  data() {
    return {
      initialBidRule: this.bidRule,
      currentBidRule: JSON.parse(JSON.stringify(this.bidRule)),
      executionDate: this.nextTriggerDate,
      lastEditedBidRule: JSON.parse(JSON.stringify(this.bidRule)),
      loading: false,
      editing: false,
    };
  },
  methods: {
    startEdit() {
      this.editing = true;
    },
    cancelEdit() {
      this.editing = false;
      this.initialBidRule = { ...JSON.parse(JSON.stringify(this.lastEditedBidRule)), editId: Math.random(), cancelled: true };
    },
    updateForm(updatedForm) {
      this.currentBidRule = updatedForm;
    },
    async deleteBidRule() {
      this.loading = true;
      const client = new A2CAClient(this.query);
      const deletedBidRule = await client.deleteBidRule(this.bidRuleId, this.selectedProfile.profileId);
      console.debug('Deleted bid rule', deletedBidRule);
      this.$toast.add({
        severity: 'success',
        summary: BID_RULE_DELETION_SUCCESS.summary,
        detail: BID_RULE_DELETION_SUCCESS.detail,
        life: 3000,
      });
      this.loading = false;
      this.$emit('deleteBidRule', this.bidRuleId);
    },
    async updateBidRule() {
      this.loading = true;
      if (!validateForm(this, this.campaigns.length)) {
        return;
      }
      const bidRule = formBidRuleToApi(this.currentBidRule, this.campaigns.length);

      const client = new A2CAClient(this.query);
      const updatedBidRule = await client.updateBidRule(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        bidRule,
        this.bidRuleId,
      );

      if (updatedBidRule?.type) {
        this.$toast.add({
          severity: 'error',
          summary: 'Could not update bid rule',
          detail: 'Make sure all the campaigns in this rule have metrics assigned.',
          life: 3000,
        });
      } else {
        this.executionDate = updatedBidRule.nextTriggerDate;

        console.debug('Updated bid rule', updatedBidRule);

        this.$toast.add({
          severity: 'success',
          summary: BID_RULE_UPDATE_SUCCESS.summary,
          detail: BID_RULE_UPDATE_SUCCESS.detail,
          life: 3000,
        });
        this.lastEditedBidRule = JSON.parse(JSON.stringify(this.currentBidRule));
        this.editing = false;
      }

      this.loading = false;
    },
    formatExecutionDate() {
      if (this.executionDate === null) {
        return '';
      }

      const formattedDate = dayjs(this.executionDate).format('dddd, D of MMMM');

      return `This rule will be executed next on ${formattedDate} at 5am UTC`;
    },
  },
};
</script>

<style scoped>
  .button-gap {
    gap: 1rem;
  }
</style>
-
