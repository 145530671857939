import dayjs from 'dayjs';
import DEFAULT_DATE_RANGE, { findPresetByValue, DATE_PRESETS } from '../constants/dateConstants';
import { SELECTED_DATE_RANGE_KEY } from '../constants/storageConstants';
import { REPORT_DATE_FORMAT } from '../constants/reportConstants';

const parseStorageDateRange = (initialDateRange) => {
  if (initialDateRange === null) {
    return null;
  }
  const parsedDateRange = JSON.parse(initialDateRange);
  let preset = findPresetByValue(parsedDateRange?.[2]) ?? DATE_PRESETS.LAST_60_DAYS;
  if (preset.value === DATE_PRESETS.NONE.value) {
    preset = null;
  }
  return [dayjs(parsedDateRange[0], REPORT_DATE_FORMAT).toDate(), dayjs(parsedDateRange[1], REPORT_DATE_FORMAT).toDate(), preset];
};

const formatStorageDateRange = (dateRange, preset) => JSON.stringify([dayjs(dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(dateRange[1]).format(REPORT_DATE_FORMAT), preset]);

const getDateRange = (useSession = false) => {
  let storage;
  if (useSession) {
    storage = sessionStorage;
  } else {
    storage = localStorage;
  }
  return parseStorageDateRange(storage.getItem(SELECTED_DATE_RANGE_KEY)) ?? [...DEFAULT_DATE_RANGE, DATE_PRESETS.LAST_60_DAYS];
};

export { formatStorageDateRange, parseStorageDateRange, getDateRange };
