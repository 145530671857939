import {
  PREDICATE_OPERANDS, METRIC_OPERANDS, METRICS, VALUE_TYPES, RESULT_TYPES, DAYS_OF_WEEK, RECURRENCE_TYPES, findConstantByValue, isBigProfile,
} from '../constants/bidRulesConstants';

/**
 * Transforms a bid rule form data object into an api bid rule object.
 * @param {Object} form - The form bid rule object.
 * @param {Integer} campaignsLength - Number of campaigns of the bid rule profile, used to handle allCampaignsSelected
 * @return {Object} the bid rule api object
 */
const formBidRuleToApi = (form, campaignsLength) => {
  const allCampaignsSelected = isBigProfile(campaignsLength) ? form.allCampaignsSelected : false;

  const bidRule = {
    name: form.name,
    campaignTargets: form.campaignTargets.map((target) => target.campaignId),
    predicateOperand: form.predicateOperand.value,
    predicates: form.predicates.map((predicate) => ({
      operand: predicate.operand.value,
      metric: predicate.metric.value,
      value: predicate.value.value ?? predicate.value,
    })),
    result: {
      type: form.result.type.value,
      value: {
        type: form.result.value.type.value,
        value: form.result.value.value,
      },
    },
    recurrence: {
      type: form.recurrence.type.value,
      dayOfWeek: form.recurrence?.dayOfWeek?.value ?? null,
    },
    allCampaignsSelected,
  };

  return bidRule;
};

/**
 * Transforms a bid rule api data object into a form bid rule object.
 * @param {Object} form - The api bid rule object.
 * @return {Object} the bid rule form object
 */
const apiBidRuleToForm = (bidRule) => {
  const form = {
    name: bidRule.name,
    campaignTargets: bidRule.campaignTargets,
    predicateOperand: findConstantByValue(PREDICATE_OPERANDS, bidRule.predicateOperand),
    predicates: bidRule.predicates.map((predicate) => ({
      operand: findConstantByValue(METRIC_OPERANDS, predicate.operand),
      metric: findConstantByValue(METRICS, predicate.metric),
      value: predicate.value,
      id: Math.random(),
    })),
    result: {
      type: findConstantByValue(RESULT_TYPES, bidRule.result.type),
      value: {
        type: findConstantByValue(VALUE_TYPES, bidRule.result.value.type),
        value: bidRule.result.value.value,
      },
    },
    recurrence: {
      type: findConstantByValue(RECURRENCE_TYPES, bidRule.recurrence.type),
      dayOfWeek: findConstantByValue(DAYS_OF_WEEK, bidRule.recurrence.dayOfWeek),
    },
    nextTriggerDate: bidRule.nextTriggerDate,
    allCampaignsSelected: bidRule.allCampaignsSelected,
  };

  return form;
};

const isDayOfWeekRecurrence = (recurrence) => recurrence === 'WEEKLY';

export { formBidRuleToApi, apiBidRuleToForm, isDayOfWeekRecurrence };
