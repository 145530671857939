class KPI {
  constructor(id, color) {
    this.id = id;
    this.color = color;
  }
}

export const KPIConstants = {
  AD_SPEND: 'cost',
  ACOS: 'trueAcos',
  CLICKS: 'clicks',
  ADJUSTED_REVENUE: 'adjustedRevenue',
  IMPRESSIONS: 'impressions',
};

export const KPIs = [
  new KPI(KPIConstants.AD_SPEND, '#C8E6C9'),
  new KPI(KPIConstants.ACOS, '#B3E5FC'),
  new KPI(KPIConstants.CLICKS, '#FFD8B2'),
  new KPI(KPIConstants.ADJUSTED_REVENUE, '#FEEDAF'),
  new KPI(KPIConstants.IMPRESSIONS, '#ECCFFF'),
];

export const findKpiById = (id) => KPIs.find((kpi) => kpi.id === id);
