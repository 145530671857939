import { ReportStatus } from '../../constants/reportConstants';
import { accumulateGroupedKpi, tryParseContent } from './reportUtils';

const buildDailyReportsFromResponse = (reportsResponse) => {
  const reports = {};
  reportsResponse.filter((report) => report.state === ReportStatus.COMPLETE).forEach((report) => {
    reports[report.reportDate] = { data: tryParseContent(report.content) };
  });
  return reports;
};

const buildIndividualCampaignDailyReportsFromResponse = (reportsResponse) => {
  const reports = {};

  reportsResponse.filter((report) => report.state === ReportStatus.COMPLETE).forEach((report) => {
    reports[report.reportDate] = {
      data: tryParseContent(report.content),
    };
  });

  return reports;
};

const buildReportsContentFromResponse = (reportsResponse) => {
  const reportsContent = [];

  reportsResponse.filter((report) => report.state === ReportStatus.COMPLETE).forEach((report) => {
    tryParseContent(report.content).forEach((entry) => reportsContent.push(entry));
  });
  return reportsContent;
};

const groupReportDataByCampaign = (reportsContent) => {
  const groupedCampaigns = reportsContent.reduce((acc, obj) => {
    const key = obj.campaignId;
    return accumulateGroupedKpi(acc, obj, key);
  }, {});
  return groupedCampaigns;
};

const mergeApiCampaignsWithReports = (reportsContent, apiCampaigns) => {
  const mergedReportsContent = [];
  apiCampaigns.forEach((apiCampaign) => {
    const reportContent = reportsContent.find((content) => content.campaignId === apiCampaign.campaignId);
    if (reportContent) {
      reportContent.campaignStatus = apiCampaign.state;
      reportContent.campaignName = apiCampaign.name;
      reportContent.profileMetricsSetted = apiCampaign.profileMetricsSetted;
      mergedReportsContent.push(reportContent);
    } else {
      mergedReportsContent.push({
        campaignId: apiCampaign.campaignId,
        campaignName: apiCampaign.name,
        campaignStatus: apiCampaign.state,
        profileMetricsSetted: apiCampaign.profileMetricsSetted,
        clicks: 0,
        cost: 0,
        impressions: 0,
        sales14d: 0,
        purchases14d: 0,
        kindleEditionNormalizedPagesRoyalties14d: 0,
      });
    }
  });

  return mergedReportsContent;
};

export {
  buildDailyReportsFromResponse, buildReportsContentFromResponse, groupReportDataByCampaign, buildIndividualCampaignDailyReportsFromResponse, mergeApiCampaignsWithReports,
};
