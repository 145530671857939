<template>
  <Calendar
    v-model="value"
    selection-mode="range"
    :max-date="maxDate"
    :min-date="minDate"
    :show-icon="true"
    :manual-input="false"
    date-format="yy-mm-dd"
    class="mr-1"
    panel-class="min-w-min w-10rem"
  >
    <template #date="slotProps">
      <span
        v-if="dateHasReport(slotProps.date.day, slotProps.date.month, slotProps.date.year)"
      >{{ slotProps.date.day }}</span>
      <span
        v-else
        class="grayed-day"
      >
        {{ slotProps.date.day }}
      </span>
    </template>
  </Calendar>
  <Dropdown
    v-model="selectedDatePreset"
    :options="datePresets"
    show-clear
    option-label="name"
    placeholder="Preset date ranges"
  />
</template>

<script>
import dayjs from 'dayjs';
import { SELECTED_DATE_RANGE_KEY } from '../constants/storageConstants';
import { formatStorageDateRange } from '../utils/dateUtils';
import { ReportStatus, REPORT_DATE_FORMAT } from '../constants/reportConstants';
import { DATE_PRESETS, findPresetByValue } from '../constants/dateConstants';

export default {
  inject: ['reportStatus', 'query'],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    reportType: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      minDate: dayjs().subtract(60, 'day').toDate(),
      maxDate: dayjs().subtract(1, 'day').toDate(),
      value: this.modelValue,
      selectedDatePreset: this.modelValue[2],
      datePresets: [
        DATE_PRESETS.LAST_7_DAYS,
        DATE_PRESETS.LAST_30_DAYS,
        DATE_PRESETS.LAST_60_DAYS,
      ],
    };
  },
  watch: {
    value: {
      handler(range) {
        if (range[1] !== null) {
          let storage;
          if (this.query?.mockAccountId) {
            storage = sessionStorage;
          } else {
            storage = localStorage;
          }
          storage.setItem(SELECTED_DATE_RANGE_KEY, formatStorageDateRange(range, this.selectedDatePreset?.value ?? DATE_PRESETS.NONE.value));
        }

        // Non-automatic calendar updates should clear date preset
        if (range[2] !== 'AUTO') {
          const updatedRange = [...range];
          updatedRange[2] = null;
          this.$emit('update:modelValue', updatedRange);
        } else {
          const updatedRange = [...range];
          updatedRange[2] = findPresetByValue(this.selectedDatePreset?.value);
          this.$emit('update:modelValue', updatedRange);
        }
      },
    },
    selectedDatePreset: {
      handler(preset) {
        if (preset !== null) {
          const end = dayjs().subtract(1, 'day').toDate();
          const start = dayjs().subtract(preset.days, 'day').toDate();
          this.value = [start, end, 'AUTO'];
        } else {
          this.value = [...this.value, 'AUTO'];
        }
      },
    },

  },
  methods: {
    dateHasReport(day, month, year) {
      if (this.reportType === null) {
        return true;
      }

      const calendarDate = dayjs(new Date(year, month, day)).format(REPORT_DATE_FORMAT);
      const currentCalendarDayData = this.reportStatus.previous60DaysStatus[calendarDate];
      if (!currentCalendarDayData) {
        return false;
      }
      const currentDayStatus = currentCalendarDayData[this.reportType];
      if (!currentDayStatus) {
        return false;
      }
      return currentDayStatus.status === ReportStatus.COMPLETE;
    },
  },
};
</script>

<style scoped>
  .grayed-day {
    color: silver;
  }
</style>
