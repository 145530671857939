<template>
  <div :hidden="true">
    {{ updateKey }}
  </div>
  <DataTable
    v-model:filters="filters"
    class="p-datatable-gridlines"
    :value="value"
    :paginator="true"
    :rows="20"
    paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    :rows-per-page-options="[10, 20, 50]"
    :resizable-columns="true"
    responsive-layout="scroll"
    current-page-report-template="Showing {first} to {last} of {totalRecords}"
    sort-mode="single"
    filter-display="row"
    :global-filter-fields="globalFilterFields"
    :scrollable="true"
    scroll-height="800px"
    scroll-direction="both"
    @filter="onFilter"
  >
    <template #empty>
      No data found.
    </template>

    <template #header>
      <div class="flex justify-content-between">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            placeholder="Search by name..."
          />
        </span>
        <MultiSelect
          v-if="tablePrefix"
          v-model="selectedColumns"
          data-key="id"
          class="w-6"
          :options="columnsInDropdown"
          option-disabled="alwaysVisible"
          option-value="id"
          option-label="name"
          placeholder="Customize columns"
          :show-toggle-all="false"
          :filter="true"
        />
      </div>
    </template>

    <ColumnGroup type="header">
      <!-- Column headers -->
      <Row>
        <Column
          v-for="col in visibleColumns"
          :key="col.id"
          :field="col.field"
          :sortable="col.sortable"
          :rowspan="col.numeric ? 1 : 2"
          :style="`width:${col?.width ?? '200px'}`"
        >
          <template #header>
            <template v-if="typeof col.header === 'string'">
              {{ col.header }}
            </template>
            <template v-else>
              <component
                :is="col.header"
                v-bind="{}"
              />
            </template>
          </template>
        </Column>
      </Row>
      <!-- Column sums -->
      <Row>
        <template
          v-for="column in visibleColumns.filter(col => col.numeric)"
          :key="column.id"
        >
          <Column
            :class="column.id + '-result text-center'"
            :field="column.field"
            :style="`width:${column?.width ?? '200px'}`"
          >
            <template #header>
              {{ formatColumn(column, sums, column.field) }}
            </template>
          </Column>
        </template>
      </Row>
    </ColumnGroup>

    <!-- Column data -->
    <template
      v-for="column, idx in visibleColumns"
      :key="column.id"
    >
      <!-- Freeze the first column -->
      <Column
        :style="`flex-grow:1; flex-basis:${column?.width ?? '200px'}`"
        field="column.field"
        :class="[{'text-center': column.content.center}, column.id + '-data']"
        :frozen="idx === 0"
      >
        <template #body="{data}">
          <router-link
            v-if="column.content.to !== null"
            :to="{...column.content.to(data), query}"
          >
            {{ data[column.field] }}
          </router-link>
          <span
            v-else-if="column.content.badge"
            :class="'campaign-badge status-' + data[column.field]"
          >{{
            data[column.field]
          }} </span>
          <span v-else-if="column.content.format !== null">
            {{ column.content.format(data) }}
          </span>
          <template
            v-else-if="column.content.slot"
          >
            <component
              :is="column.content.slot.component"
              v-bind="column.content.slot.props(data)"
            />
          </template>
          <span v-else>
            {{ data[column.field] }}
          </span>
        </template>
      </Column>
    </template>
  </DataTable>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
/**
 * column object specification:
 *    id: column id
      field: column field name
      header: column header name
      sortable: whether the column is sortable or not
      numeric: whether the column is numeric or not
      decimal: if true, number will be formatted as decimal
      content: {
        center: if true, centers the text of the column
        to: if set, wraps the content in a router link. e.g value: (data) => ({ name: 'adGroups', params: { id: data.campaignId } })
        badge: if true, wraps the result in a badge using its status
        remove: string to remove before aggregation data in the column
        format: function that receives the column data and returns the formatted value of the column. If null, no formatting is done.
      },
      aggregation: how to aggregate data when calculation the total header
 */

export default {
  inject: ['query'],
  props: {
    value: {
      type: Array,
      required: true,
    },
    globalFilterFields: {
      type: Array, required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    sums: {
      type: Object,
      required: true,
    },
    tablePrefix: {
      type: String,
      required: true,
    },
  },
  data() {
    const columnToSelectItem = (column) => {
      const name = column.title ?? column.header;
      const ret = { id: column.id, name, alwaysVisible: column.alwaysVisible };
      return ret;
    };

    const savedSelectedColumns = this.tablePrefix ? localStorage.getItem(`${this.tablePrefix}-selectedColumns`) : undefined;
    const columnsInDropdown = this.columns.filter((column) => !column.notInFilter).map(columnToSelectItem);
    const columnsById = this.columns.reduce((acc, next) => {
      acc[next.id] = next;
      return acc;
    }, {});

    let selectedColumns;
    let visibleColumns;

    if (savedSelectedColumns) {
      selectedColumns = JSON.parse(savedSelectedColumns).filter((columnId) => columnsById[columnId]);
      const alwaysVisibleColumns = this.columns.filter((column) => !!column.alwaysVisible && !column.notInFilter).map((column) => column.id);
      const columnsNotInFilter = this.columns.filter((column) => column.notInFilter);

      selectedColumns = Array.from(new Set([...alwaysVisibleColumns, ...selectedColumns]));
      visibleColumns = [...columnsNotInFilter, ...selectedColumns.map((column) => columnsById[column]).filter(Boolean)];
    } else {
      visibleColumns = this.columns.filter((column) => column.visible);
      selectedColumns = visibleColumns.filter((column) => !column.notInFilter).map(({ id }) => id);
    }

    if (!savedSelectedColumns && this.tablePrefix) {
      localStorage.setItem(`${this.tablePrefix}-selectedColumns`, JSON.stringify(selectedColumns));
    }

    if (!visibleColumns.length) { // is possible if we changed all column ids but user has old in the browser
      visibleColumns = this.columns.filter((column) => column.visible);
    }

    visibleColumns.sort((a, b) => a.order - b.order);

    return {
      updateKey: 0,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },

      },
      visibleColumns,
      columnsInDropdown,
      selectedColumns,
      columnsById,
    };
  },
  watch: {
    selectedColumns: {
      handler(columnIds) {
        if (this.tablePrefix) {
          localStorage.setItem(`${this.tablePrefix}-selectedColumns`, JSON.stringify(columnIds));
        }

        const columnsNotInFilter = this.columns.filter((column) => column.notInFilter);
        this.visibleColumns = [...columnsNotInFilter, ...columnIds.map((id) => this.columnsById[id]).filter(Boolean)].sort((a, b) => a.order - b.order);
      },
    },
  },
  methods: {

    onFilter() {
      this.updateKey += 1;
    },
    formatColumn(col, data, field) {
      const formattedContent = col.content.format ? col.content.format(data) : data[field];
      return formattedContent;
    },
  },
};
</script>

<style scoped lang="scss">
@import "..//assets/styles/dashboard/campaigns.scss";
</style>
