import { createStore } from 'vuex';
import authStore from './authStore';
import localSettingsStore from './localSettingsStore';

export default createStore(
  {
    modules: {
      auth: authStore,
      settings: localSettingsStore,
    },
  },
);
