const ReportType = {
  CAMPAIGNS: 'CAMPAIGNS',
  AD_GROUPS: 'AD_GROUPS',
  KEYWORDS: 'KEYWORDS',
  PRODUCT_ADS: 'PRODUCT_ADS',
  TARGETS: 'TARGETS',
};

const ApiReportType = {
  CAMPAIGNS_AND_ADGROUPS: 'CAMPAIGNS_AND_ADGROUPS',
  KEYWORDS_AND_TARGETS: 'KEYWORDS_AND_TARGETS',
  PRODUCT_ADS: 'PRODUCT_ADS',
};

const ReportStatus = {
  NO_REPORT: 'NO_REPORT',
  PENDING_REQUEST: 'PENDING_REQUEST',
  WAITING_REPORT: 'WAITING_REPORT',
  COMPLETE: 'COMPLETE',
};

const REPORT_DATE_FORMAT = 'YYYY-MM-DD';

export {
  ReportType, ApiReportType, ReportStatus, REPORT_DATE_FORMAT,
};
