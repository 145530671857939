<template>
  <Card>
    <template #title>
      <h2 class="text-2xl">
        Bidding Rules List
      </h2>
    </template>
    <template #content>
      <TabView>
        <TabPanel header="Increase">
          <BidRuleListingTab
            :selected-profile="selectedProfile"
            :campaigns="campaigns"
            :bid-rules="increaseBidRules"
            @deleteBidRule="deleteBidRule"
          />
        </TabPanel>
        <TabPanel header="Decrease">
          <BidRuleListingTab
            :selected-profile="selectedProfile"
            :campaigns="campaigns"
            :bid-rules="decreaseBidRules"
            @deleteBidRule="deleteBidRule"
          />
        </TabPanel>
          <TabPanel header="Pause/Enable">
          <BidRuleListingTab
            :selected-profile="selectedProfile"
            :campaigns="campaigns"
            :bid-rules="pauseAndEnableBidRules"
            @deleteBidRule="deleteBidRule"
          />
        </TabPanel>
        <TabPanel header="Automate">
          <BidRuleListingTab
            :selected-profile="selectedProfile"
            :campaigns="campaigns"
            :bid-rules="automateBidRules"
            @deleteBidRule="deleteBidRule"
          />
        </TabPanel>
      </TabView>
    </template>
  </Card>
  <Toast />
</template>
<script>
import BidRuleListingTab from './BidRuleListingTab.vue';

export default {
  components: { BidRuleListingTab },
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    bidRules: {
      type: Array,
      required: true,
    },

  },
  emits: ['deleteBidRule'],
  computed: {
    increaseBidRules() {
      return this.bidRules.filter((rule) => rule.result.type === 'INCREASE');
    },
    decreaseBidRules() {
      return this.bidRules.filter((rule) => rule.result.type === 'DECREASE');
    },
    pauseAndEnableBidRules() {
      return this.bidRules.filter((rule) => [
        'PAUSE',
        'RE_ENABLE',
      ].includes(rule.result.type));
    },
    automateBidRules() {
      return this.bidRules.filter((rule) => rule.result.type === 'AUTOMATE_BID_OPTIMIZATION');
    },
  },
  methods: {
    deleteBidRule(bidRuleId) {
      this.$emit('deleteBidRule', bidRuleId);
    },
  },
};
</script>
