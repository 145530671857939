<template>
  <InputNumber
    v-model="value"
    :min="0"
    :use-grouping="false"
    :max-fraction-digits="maxFractionDigits"
    :allow-empty="false"
    :max="max"
    :disabled="!editable"
    locale="en-US"
    @input="updateValue"
  />
  <Dropdown
    v-model="type"
    :options="types"
    placeholder="Select a value type"
    :disabled="!editable"
    @change="updateType"
  >
    <template #value="slotProps">
      <span v-if="slotProps.value.value === 'FIXED'">{{ currencySymbol() }}</span>
      <span v-else>{{ slotProps.value.name }}</span>
    </template>
    <template #option="slotProps">
      <span v-if="slotProps.option.value === 'FIXED'">{{ currencySymbol() }}</span>
      <span v-else>{{ slotProps.option.name }}</span>
    </template>
  </Dropdown>
</template>
<script>
import { VALUE_TYPES } from '../../../../constants/bidRulesConstants';
import { currencySymbolFromCode } from '../../../../utils/formatUtils';

export default {
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    editId: {
      type: Number,
      required: false,
      default: 0,
    },
    // eslint-disable-next-line vue/no-unused-properties
    cancelled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue.value,
      type: this.modelValue.type,
      types: VALUE_TYPES,
      maxFractionDigits: 2,
      max: null,
    };
  },
  watch: {

    editId: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.value = this.modelValue.value;
          this.type = this.modelValue.type;
        }
      },
    },
  },
  methods: {
    updateValue(e) {
      this.$emit('update:modelValue', { ...this.modelValue, value: e.value });
    },
    updateType(e) {
      this.$emit('update:modelValue', { ...this.modelValue, type: e.value });
    },
    currencySymbol() {
      return currencySymbolFromCode(this.$store.state.auth.selectedProfile.currencyCode);
    },
  },
};
</script>
