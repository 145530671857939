import dayjs from 'dayjs';

const DEFAULT_DATE_RANGE = [dayjs().subtract(60, 'day').toDate(),
  dayjs().subtract(1, 'day').toDate()];

export const DATE_PRESETS = {
  NONE: {
    name: 'None',
    value: 'NONE',
    days: 0,
  },
  LAST_7_DAYS: {
    name: 'Last 7 days',
    value: 'LAST_7_DAYS',
    days: 7,
  },
  LAST_30_DAYS: {
    name: 'Last 30 days',
    value: 'LAST_30_DAYS',
    days: 30,
  },
  LAST_60_DAYS: {
    name: 'Last 60 days',
    value: 'LAST_60_DAYS',
    days: 60,
  },
};

export const findPresetByValue = (value) => Object.values(DATE_PRESETS).find((preset) => preset.value === value);

export default DEFAULT_DATE_RANGE;
