<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <div v-else>
    <div :hidden="true">
      {{ updateKey }}
    </div>
    <SumHeaderTable
      :value="campaigns"
      :global-filter-fields="['campaignName']"
      :columns="columns"
      :sums="total"
      table-prefix="general"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { markRaw, defineAsyncComponent } from 'vue';
import { mergeApiCampaignsWithReports } from '../../../utils/reports/campaignReportUtils';
import generateCommonColumns from '../../../utils/tableUtils';
import SumHeaderTable from '../../SumHeaderTable.vue';

export default {
  components: { SumHeaderTable },
  props: {
    reports: {
      type: Object,
      required: true,
    },
    apiCampaigns: {
      type: Array,
      required: true,
    },
    total: {
      type: Object, required: true,
    },
  },
  data() {
    const columns = [
      {
        id: 'campaignName',
        field: 'campaignName',
        header: 'Name',
        sortable: true,
        numeric: false,
        visible: true,
        alwaysVisible: true,
        notInFilter: true,
        decimal: false,
        width: '300px',
        content: {
          center: false,
          to: null,
          badge: null,
          format: null,
          slot: {
            component: markRaw(defineAsyncComponent(() => import('./campaign/CampaignStateWarning.vue'))),
            props: (data) => ({
              profileMetricsSetted: data.profileMetricsSetted,
              sales: data.sales14d,
              kenpRoyalties: data.kindleEditionNormalizedPagesRoyalties14d,
              campaignState: data.campaignStatus,
              campaignId: data.campaignId,
              campaignName: data.campaignName,
            }),
          },
        },
      },
      {
        id: 'campaignStatus',
        field: 'campaignStatus',
        header: 'Status',
        sortable: true,
        numeric: false,
        visible: true,
        alwaysVisible: true,
        notInFilter: true,
        decimal: false,
        width: '100px',
        content: {
          center: false,
          to: null,
          badge: true,
          format: null,
        },
      },
      ...generateCommonColumns(() => this.$store.state.auth.selectedProfile),
    ].map((column, index) => ({ ...column, order: index }));

    return {
      loading: false,
      updateKey: 0,
      campaigns: null,

      filteredCampaigns: null,
      columns,
    };
  },
  computed: {
    ...mapGetters(['royalties']),
  },
  created() {
    this.loading = true;

    const mergedCampaigns = mergeApiCampaignsWithReports(this.reports, this.apiCampaigns);
    this.campaigns = mergedCampaigns;
    this.filteredCampaigns = this.campaigns.slice(0, 10);

    this.loading = false;
  },
};
</script>
