<template>
  <p v-if="bidRules.length === 0">
    No data.
  </p>
  <template v-else>
    <div
      v-for="bidRule in bidRules"
      :key="bidRule.id"
      class="pb-3"
    >
      <EditableBidRule
        :selected-profile="selectedProfile"
        :campaigns="campaigns"
        :bid-rule="formatApiBidRule(bidRule)"
        :bid-rule-id="bidRule.id"
        :next-trigger-date="bidRule.nextTriggerDate"
        @deleteBidRule="deleteBidRule"
      />
    </div>
  </template>
</template>
<script>

import EditableBidRule from '../edit/EditableBidRule.vue';
import { apiBidRuleToForm } from '../../../../utils/bidRuleUtils';

export default {
  components: { EditableBidRule },
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    bidRules: {
      type: Array,
      required: true,
    },

  },
  emits: ['deleteBidRule'],
  methods: {
    formatApiBidRule(bidRule) {
      const campaignTargets = this.campaigns.filter((campaign) => bidRule.campaignTargets.includes(campaign.campaignId));

      return apiBidRuleToForm({ ...bidRule, campaignTargets });
    },
    deleteBidRule(bidRuleId) {
      this.$emit('deleteBidRule', bidRuleId);
    },
  },
};
</script>
