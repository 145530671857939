import { datadogRum } from '@datadog/browser-rum';

const env = process.env.NODE_ENV ?? 'development';

const isNotDevelopment = env !== 'development';
if (isNotDevelopment) {
  datadogRum.init({
    applicationId: '0c24da09-4b2d-4707-9627-f5a568fa3e4b',
    clientToken: 'pubde6ee3afcf8a897141aa03408469914e',
    site: 'datadoghq.eu',
    service: 'booksflyer',
    env, // Make it env variable
    sessionSampleRate: 100,
    sessionReplaySampleRate: 40,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

// eslint-disable-next-line import/prefer-default-export
export const setUser = (account) => {
  if (isNotDevelopment) {
    const user = {
      country: account.country,
      id: account.id,
      email: account.email,
      name: account.name,
      type: account.type,
      plan: account.pricing?.pricingPlan?.planType ?? 'UNKNOWN',
    };
    datadogRum.setUser(user);
  }
};
