<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <template v-else>
    <div
      class="pb-2"
    >
      <MultiSelect
        v-model="selectedCampaignsModel"
        :options="campaigns"
        option-label="name"
        placeholder="Select a campaign..."
        :filter="true"
        :selection-limit="5"
        display="chip"
      />
    </div>
    <Dropdown
      v-model="selectedKpi"
      :options="kpiOptions"
      option-label="name"
      placeholder="Select a KPI..."
    />
    <div class="flex justify-content-center pt-4">
      <Chart
        type="line"
        :data="generalChartData"
        :options="generalChartOptions"
        :width="800"
        :height="400"
      />
    </div>
  </template>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import dayjs from 'dayjs';
import { KPIConstants } from '../../../constants/kpiConstants';
import { getProfileSelectedCampaignsKey } from '../../../constants/storageConstants';
import A2CAAuthorizedClient from '../../../api/a2caAuthorizedClient';
import {
  ReportType, REPORT_DATE_FORMAT,
} from '../../../constants/reportConstants';

const CAMPAIGN_COLORS = [
  '#E8114A',
  '#6B4FCD',
  '#71C0F5',
  '#5BBB4B',
  '#EBEB41',
];

export default {
  inject: ['query'],
  props: {
    campaigns: {
      type: Object,
      required: true,
    },
    dateRange: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      reports: null,
      selectedCampaignIds: null,
      selectedCampaignsModel: null,
      selectedKpi: null,
      kpiOptions: [
        { name: 'Impressions', value: KPIConstants.IMPRESSIONS },
        { name: 'Clicks', value: KPIConstants.CLICKS },
        { name: 'Total Ad Spend', value: KPIConstants.AD_SPEND },
        { name: 'Adjusted Revenue', value: KPIConstants.ADJUSTED_REVENUE },
        { name: 'Estimated ACOR', value: KPIConstants.ACOS },
      ],
      generalChartData: null,
      generalChartOptions: {
        responsive: false,
        stacked: false,
        plugins: {
          legend: {
            labels: {
              color: '#495057',
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: '#495057',
            },
            grid: {
              color: '#ebedef',
            },
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            ticks: {
              color: '#495057',
            },
            grid: {
              color: '#ebedef',
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(['selectedProfile', 'royalties']),
  },
  watch: {
    selectedCampaignsModel: {
      async handler(val) {
        this.loading = true;
        // Fetch reports
        const enabledCampaignIds = val.map((c) => c.campaignId);
        const client = new A2CAAuthorizedClient(this.query);
        const reportsResponse = await client.getCampaignChartData(
          this.selectedProfile.profileId,
          this.selectedProfile.region,
          ReportType.CAMPAIGNS,
          dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
          enabledCampaignIds,
        );

        this.reports = reportsResponse.dailyAggregations;
        this.updateCampaignsDataset();
        this.loading = false;
      },
      deep: true,
    },
    selectedKpi() {
      this.updateCampaignsDataset();
    },
  },
  async mounted() {
    this.loading = true;
    console.debug('Fetching campaign reports...');

    const initialCampaignIds = this.initialCampaignIds();
    // Set campaign IDs
    const campaignIds = this.campaigns.map((campaign) => campaign.campaignId);
    const areAllIdsValid = initialCampaignIds?.every((campaignId) => campaignIds.includes(campaignId)) ?? false;
    if (initialCampaignIds === null || !areAllIdsValid) {
      this.selectedCampaignIds = this.campaigns.slice(0, 3).map((campaign) => campaign.campaignId);
    } else {
      this.selectedCampaignIds = initialCampaignIds;
    }

    // Set campaigns model
    const campaigns = [];
    this.selectedCampaignIds.forEach((campaignId) => {
      campaigns.push(this.campaigns.find((campaign) => campaign.campaignId === campaignId));
    });

    // Fetch reports
    const enabledCampaignIds = this.selectedCampaignIds;
    const client = new A2CAAuthorizedClient(this.query);
    const reportsResponse = await client.getCampaignChartData(
      this.selectedProfile.profileId,
      this.selectedProfile.region,
      ReportType.CAMPAIGNS,
      dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
      enabledCampaignIds,
    );

    this.reports = reportsResponse.dailyAggregations;
    this.generalChartData = {
      labels: Object.keys(this.reports).map((reportDate) => dayjs(reportDate).format('YYYY-MM-DD')),
      datasets: [],
    };
    this.selectedCampaignsModel = campaigns;
    [this.selectedKpi] = this.kpiOptions;
    this.loading = false;
  },
  methods: {
    initialCampaignIds() {
      let storage;
      if (this.query?.mockAccountId) {
        storage = sessionStorage;
      } else {
        storage = localStorage;
      }
      return JSON.parse(storage.getItem(getProfileSelectedCampaignsKey(this.selectedProfile.profileId)));
    },
    buildDataset(campaign, kpi, color) {
      // Get all specific kpi data of campaigns per day
      const dataFilteredByCampaign = Object.values(this.reports).map((report) => {
        const filteredData = report.reportData.filter((data) => data.campaignId === campaign.campaignId);
        return {
          ...report,
          data: filteredData,
        };
      });

      const data = dataFilteredByCampaign.reduce((prev, current, index) => {
        prev.push(
          dataFilteredByCampaign[index].data.reduce((prevCampaign, currentCampaign) => prevCampaign + currentCampaign[kpi.value], 0),
        );
        return prev;
      }, []);

      return {
        label: campaign.name,
        fill: false,
        borderColor: color,
        yAxisID: 'y',
        tension: 0.4,
        data,
      };
    },

    updateCampaignsDataset() {
      this.setSelectedCampaigns({
        selectedCampaigns: this.selectedCampaignsModel.map((campaign) => campaign.campaignId),
        profileId: this.selectedProfile.profileId,
      }, !!this.query?.mockAccountId);
      this.generalChartData.datasets = this.selectedCampaignsModel.map((campaign, idx) => this.buildDataset(campaign, this.selectedKpi, CAMPAIGN_COLORS[idx]));
      if (this.selectedKpi.value === KPIConstants.ACOS) {
        this.generalChartOptions.scales.y.ticks.callback = (value) => `${value}%`;
      } else {
        this.generalChartOptions.scales.y.ticks.callback = undefined;
      }
    },
    ...mapMutations(['setSelectedCampaigns']),
  },
};
</script>

<style scoped>
.p-multiselect {
  max-width: 100%;
}
</style>
