<template>
  <Dialog
    v-model:visible="showCampaignMultiselect"
    modal
    header="Campaigns"
    :style="{ width: '50vw' }"
    :closable="false"
    :visible="showCampaignDialog"
  >
    <div class="flex flex-wrap pb-5">
      <div class="target-dropdown">
        <label
          for="campaignTargets"
          class="block pb-2 text-base font-semibold"
        >
          Campaigns
          <span
            v-show="displayCampaignMetricWarning()"
            v-tooltip="'Some campaigns do not have metrics assigned. Please update this in the Metrics section or remove the campaigns from the rule'"
            class="pi pi-exclamation-triangle text-orange-500"
          />
        </label>
        <MultiSelect
          id="campaignTargets"
          v-model="value.campaignTargets"
          class="full-dropdown"
          :options="campaigns"
          option-label="name"

          placeholder="Select one or more campaigns"
          :filter="true"
          display="chip"
          :disabled="!editable || value.allCampaignsSelected"
          :show-toggle-all="false"
        />
      </div>
    </div>
    <div class="flex">
      <div class="flex justify-content-end w-full">
        <div class="flex align-items-center justify-content-end w-full">
          <Checkbox
            v-model="value.allCampaignsSelected"
            input-id="selectAllCampaignsCheckbox"
            :binary="true"
          />
          <label
            for="selectAllCampaignsCheckbox"
            class="ml-2 mr-3"
          > Select all campaigns </label>
        </div>
        <Button
          size="small"
          @click="showCampaignMultiselect = false"
        >
          OK
        </Button>
      </div>
    </div>
  </Dialog>
  <div class="flex flex-wrap pb-5">
    <div class="mr-1">
      <label
        for="campaignName"
        class="block pb-2 text-base font-semibold"
      >
        Rule Name <span class="font-italic font-light">(optional)</span>
      </label>
      <InputText
        id="campaignName"
        v-model="value.name"
        :disabled="!editable"
      />
    </div>
    <div
      v-if="isBigCampaignProfile()"
      class="target-dropdown flex align-items-end"
    >
      <Button
        class="p-button-secondary"
        label="Select campaigns"
        icon="pi pi-external-link"
        :disabled="!editable"
        @click="showCampaignMultiselect = true"
      />
    </div>
    <template v-else>
      <div
        class="target-dropdown"
      >
        <label
          for="campaignTargets"
          class="block pb-2 text-base font-semibold"
        >
          Targeted Campaigns
          <span
            v-show="displayCampaignMetricWarning()"
            v-tooltip="'Some campaigns do not have metrics assigned. Please update this in the Metrics section or remove the campaigns from the rule'"
            class="pi pi-exclamation-triangle text-orange-500"
          />
        </label>
        <MultiSelect
          id="campaignTargets"
          v-model="value.campaignTargets"
          class="full-dropdown"
          :options="campaigns"
          option-label="name"

          placeholder="Select one or more campaigns"
          :filter="true"
          display="chip"
          :disabled="!editable"
        />
      </div>
    </template>
  </div>
  <div class="flex pb-5">
    <div class="if-label font-semibold text-xl">
      <p>IF</p>
    </div>
    <div class="flex flex-row align-items-end button-gap">
      <div>
        <div
          v-for="(predicate, index) in value.predicates"
          :key="value.predicates[index].id"
          class="flex flex-row button-gap pt-3"
        >
          <BidRulePredicate
            v-model="value.predicates[index]"
            :editable="editable"
          />
          <Button
            v-if="index !== 0"
            v-show="editable"
            class="p-button-danger"
            @click="() => removePredicate(value.predicates[index].id)"
          >
            Remove
          </Button>
        </div>
      </div>
      <Button
        v-show="editable"
        class="p-button-secondary"
        @click="addPredicate"
      >
        Add
      </Button>
    </div>
    <div class="flex flex-1 justify-content-end">
      <div>
        <label
          for="campaignTargets"
          class="block pb-2 text-base font-semibold"
        >
          Rule Operator
        </label>
        <Dropdown
          v-model="value.predicateOperand"
          class="small-dropdown"
          :options="predicateOperands"
          option-label="name"
          placeholder="Select an operand"
          :disabled="!editable"
        />
      </div>
    </div>
  </div>
  <div class="flex">
    <div class="if-label font-semibold text-xl">
      <p>THEN</p>
    </div>
    <div class="flex predicate-gap">
      <Dropdown
        v-model="value.result.type"
        :options="resultTypes"
        option-label="name"
        placeholder="Select a result type"
        class="metric-dropdown"
        :disabled="!editable || disableResultType"
      />

      <BidRuleValue
        v-if="value.result.type.value === 'INCREASE' || value.result.type.value === 'DECREASE'"
        v-model="value.result.value"
        :editable="editable"
        :edit-id="value.editId"
        :cancelled="value.campaignTargets.cancelled"
      />
    </div>
  </div>
  <div class="flex justify-content-end">
    <div class="flex-column flex">
      <label
        for="campaignTargets"
        class="block pb-2 text-base font-semibold"
      >
        Frequency
      </label>
      <div>
        <Dropdown
          v-model="value.recurrence.type"
          :options="recurrenceTypes"
          option-label="name"
          placeholder="Select a recurrence type"
          :disabled="!editable"
        />

        <Dropdown
          v-if="hasWeekdays(value.recurrence.type.value)"
          v-model="value.recurrence.dayOfWeek"
          :options="weekdays"
          option-label="name"
          placeholder="Select a day"
          :disabled="!editable"
        />
      </div>
    </div>
  </div>
</template>
<script>
import BidRulePredicate from './BidRulePredicate.vue';
import BidRuleValue from './BidRuleValue.vue';
import {
  PREDICATE_OPERANDS, RESULT_TYPES, DAYS_OF_WEEK, RECURRENCE_TYPES, isBigProfile,
} from '../../../../constants/bidRulesConstants';
import { isDayOfWeekRecurrence } from '../../../../utils/bidRuleUtils';

export default {
  components: { BidRulePredicate, BidRuleValue },
  props: {
    initialValue: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableResultType: {
      type: Boolean,
      required: false,
      default: false,
    },
    campaigns: {
      type: Array,
      required: true,
    },

  },
  emits: ['updateForm'],
  data() {
    return {
      value: JSON.parse(JSON.stringify(this.initialValue)),
      predicateOperands: PREDICATE_OPERANDS,
      resultTypes: RESULT_TYPES,
      recurrenceTypes: RECURRENCE_TYPES,
      weekdays: DAYS_OF_WEEK,
      showCampaignMultiselect: false,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.$emit('updateForm', val);
      },
      deep: true,
    },
    initialValue: {
      handler(val) {
        const value = JSON.parse(JSON.stringify(val));
        if (value.cancelled) {
          value.campaignTargets.cancelled = true;
        }
        delete value.cancelled;

        value.campaignTargets.editId = value.editId;

        this.value = value;
      },
      deep: true,
    },

  },
  methods: {
    addPredicate() {
      this.value.predicates.push({
        operand: { name: '<', value: 'LESS_THAN' },
        metric: { name: 'Impressions', value: 'IMPRESSIONS' },
        value: 0,
        id: new Date().getMilliseconds(),
      });
    },
    removePredicate(id) {
      this.value.predicates = this.value.predicates.filter((predicate) => predicate.id !== id);
    },
    hasWeekdays(recurrence) {
      return isDayOfWeekRecurrence(recurrence);
    },
    displayCampaignMetricWarning() {
      if (this.value.result.type.value === 'AUTOMATE_BID_OPTIMIZATION') {
        // In AUTOMATE_BID_OPTIMIZATION rules, TRUE_ACOS is always mandatory, so show the warning if any of the campaign targets has no metrics.
        if (this.value.allCampaignsSelected) {
          return !this.campaigns.every((c) => c.hasMetrics);
        }
        return !this.value.campaignTargets.every((c) => c.hasMetrics);
      }

      // In the rest of bid rules, if there is no TRUE_ACOS predicate, do not show any warning.
      // If there is a TRUE_ACOS predicate, show the warning if any of the campaign targets has no metrics.
      if (this.value.predicates.every((p) => p.metric.value !== 'TRUE_ACOS')) {
        return false;
      }

      if (this.value.allCampaignsSelected) {
        return !this.campaigns.every((c) => c.hasMetrics);
      }
      return !this.value.campaignTargets.every((c) => c.hasMetrics);
    },
    isBigCampaignProfile() {
      return isBigProfile(this.campaigns.length);
    },
  },
};
</script>

<style scoped>
    .if-label {
        flex-basis: 10%
    }
    .target-dropdown {
      flex: 2;
    }
    .full-dropdown {
      min-width: 25rem;
      max-width: 25rem;
    }
    .small-dropdown {
      width: 8rem;
    }
    .button-gap {
      gap: 1rem;
    }
    .predicate-gap {
      gap: 1rem;
    }
    .metric-dropdown {
      width: 12rem;
    }
</style>

<style>
  .p-multiselect-header .p-checkbox::before {
    content: "Select All ";
    margin-right: 0.5rem;
    display: inline-block;
    width: 65px;
    height: 15px;
    margin-right: 5px;
    margin-top: 2px;
  }
  .p-multiselect-header .p-checkbox {
    width: 90px !important;
  }
</style>
