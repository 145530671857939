<template>
  <Toast />
  <Dialog
    v-model:visible="showCampaignMultiselect"
    modal
    header="Quick Rule Setup: Campaign Selection"
    :style="{ width: '50vw' }"
    :closable="true"
    :visible="showCampaignDialog"
  >
    <div class="">
      <label
        for="campaignTargets"
        class="block pb-2 text-base font-semibold"
      >
        Campaigns
        <span
          v-show="displayCampaignMetricWarning()"
          v-tooltip="'Some campaigns do not have metrics assigned. Please update this in the Metrics section or remove the campaigns from the rule'"
          class="pi pi-exclamation-triangle text-orange-500"
        />
      </label>
      <MultiSelect
        id="campaignTargets"
        v-model="campaignTargets"
        class="w-6"
        :options="campaigns"
        option-label="name"

        placeholder="Select one or more campaigns"
        :filter="true"
        display="chip"
        :disabled="allCampaignsSelected"
        :show-toggle-all="false"
      />
    </div>
    <div class="flex">
      <div class="flex justify-content-end w-full">
        <div class="flex align-items-center justify-content-end w-full">
          <Checkbox
            v-model="allCampaignsSelected"
            input-id="selectAllCampaignsCheckbox"
            :binary="true"
          />
          <label
            for="selectAllCampaignsCheckbox"
            class="ml-2 mr-3"
          > Select all campaigns </label>
        </div>
        <Button
          size="small"
          :disabled="loading"
          @click="generateRules"
        >
          OK
        </Button>
      </div>
    </div>
  </Dialog>
  <div class="flex-initial flex align-items-center justify-content-center card-gap mt-8">
    <Card
      style="width: 25em"
      class="text-center"
    >
      <template #header>
        <div>
          <img
            src="images/wand.png"
            alt="Quick bid rule setup wand icon"
            class="card-icon"
          >
        </div>
      </template>
      <template #title>
        Quick Rule Setup
      </template>
      <template #content>
        <p>
          Choose this option if you are not sure of the rules you should have in place. We'll provide them for you. You can customize them later on.
        </p>
      </template>
      <template #footer>
        <Button
          label="Start"
          @click="showCampaignMultiselect = true"
        />
      </template>
    </Card>
    <Card
      style="width: 25em"
      class="text-center"
    >
      <template #header>
        <div>
          <img
            src="images/pencil.png"
            alt="Custom bid rule setup pencil icon"
            class="card-icon"
          >
        </div>
      </template>
      <template #title>
        Custom Rule Setup
      </template>
      <template #content>
        <p>
          Choose this option if you already know how to manage and optimize your campaigns using rules. You will start from a blank page.
        </p>
      </template>
      <template #footer>
        <Button
          label="Start"
          @click="redirectEmptyRules"
        />
      </template>
    </Card>
  </div>
</template>

<script>
import A2CAAuthorizedClient from '../../../../api/a2caAuthorizedClient';

export default {
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
  },
  emits: ['clear-selected-profile', 'change-menu-layout', 'generate-bid-rules'],
  data() {
    return {
      showCampaignMultiselect: false,
      campaignTargets: [],
      allCampaignsSelected: false,
      loading: false,
    };
  },
  methods: {
    redirectEmptyRules() {
      this.$router.push({
        name: 'bidRules',
        query: {
          setup: '0',
        },
      });
    },
    displayCampaignMetricWarning() {
      if (this.allCampaignsSelected) {
        return !this.campaigns.every((c) => c.hasMetrics);
      }
      return !this.campaignTargets.every((c) => c.hasMetrics);
    },
    async generateRules() {
      this.loading = true;
      const client = new A2CAAuthorizedClient(this.query);
      const generatedRules = await client.generateBidRules(this.selectedProfile.profileId,
        this.selectedProfile.region, this.campaignTargets.map((c) => c.campaignId), this.allCampaignsSelected);
      if (generatedRules?.type === 'INVALID_BID_RULE') {
        this.$toast.add({
          severity: 'error',
          summary: 'Could not create bid rule',
          detail: 'Make sure all the campaigns in this rule have metrics assigned.',
          life: 3000,
        });
        this.loading = false;
      } else {
        this.$emit('generate-bid-rules', generatedRules);
        this.showCampaignMultiselect = false;
        this.loading = false;
        this.$toast.add({
          severity: 'success',
          summary: 'Rules generated',
          detail: 'Your bid rules have been generated.',
          life: 3000,
        });
        this.$router.push({
          name: 'bidRules',
          query: {
            setup: '0',
          },
        });
      }
    },
  },
};
</script>

<style scoped>
  .card-gap {
    gap: 10%;
  }

  .card-icon {
    width: 64px;
  }
</style>
