import { TOKEN_KEY, SELECTED_PROFILE_KEY } from '../constants/storageConstants';

export default {
  /**
   *
   * account: logged in account
   * profiles: profiles associated with the account
   * token: app's accessToken
   * selectedProfile: current profile
   */
  state: () => ({
    account: {},
    profiles: {},
    maxProfiles: null,
    adSpendPercentage: null,
    currentAdSpend: null,
    enterpriseVariableCost: null,
    token: null,
    selectedProfile: null,
    metrics: [],
  }),
  getters: {
    selectedProfile: (state) => state.selectedProfile,
    accountLinked: (state) => state.account.accountLinked,
    profileSettings: (state) => state.account.settings.profileSettings,
    email: (state) => state.account.email,
    accountType: (state) => state.account.type,
    userId: (state) => state.account.id,
    username: (state) => state.account.name,
    royalties: (state) => state.account.settings.salesRoyaltiesPercentage,
    trialStatus: (state) => state.account.pricing.trialStatus ?? '',
    accountDownloaded: (state) => state.account.downloadState.firstTimeDownloaded,
    profileDownloads: (state) => state.account.downloadState.profileDownloads,
    plan: (state) => state.account.pricing.pricingPlan,
    pricing: (state) => state.account.pricing,
    settings: (state) => state.account.settings,
    adSpendPercentage: (state) => state.adSpendPercentage,
    currentAdSpend: (state) => state.currentAdSpend,
    metrics: (state) => state.metrics,
    enterpriseVariableCost: (state) => state.enterpriseVariableCost,
    numberOfEnabledProfiles: (state) => Object.values(state.account.enabledProfiles).filter((v) => v === true).length,
    enabledProfiles: (state) => state.account.enabledProfiles || [],
  },
  mutations: {
    setAccount(state, account) {
      state.account = account.account;
      state.profiles = account.profiles;
      state.maxProfiles = account.maxProfiles;
      state.adSpendPercentage = account.adSpendPercentage;
      state.currentAdSpend = account.currentAdSpend;
      state.enterpriseVariableCost = account.enterpriseVariableCost;
    },
    setSettings(state, settings) {
      state.account.settings = settings;
    },
    setProfileSettings(state, payload) {
      state.account.settings = {
        ...state.account.settings,
        profileSettings: {
          ...state.account.settings.profileSettings,
          [payload.profileId]: payload.profileSettings,
        },
      };
    },
    setSelectedProfile(state, payload) {
      const { profile, useSession } = payload;
      state.selectedProfile = profile;
      let storage;
      if (useSession) {
        storage = sessionStorage;
      } else {
        storage = localStorage;
      }
      storage.setItem(SELECTED_PROFILE_KEY, JSON.stringify({ [state.account.id]: profile }));
    },
    setEnabledProfiles(state, profiles) {
      state.account.enabledProfiles = profiles;
    },
    setPricing(state, pricing) {
      state.account.pricing = pricing;
    },
    setMetrics(state, metrics) {
      state.metrics = metrics;
    },
    login(state, token) {
      state.token = token;
      localStorage.setItem(TOKEN_KEY, token);
    },
    logout(state) {
      state.account = {};
      state.profiles = [];
      state.metrics = [];
      state.token = null;
      state.selectedProfile = null;
      localStorage.clear();
    },
  },
};
