import { findPlacementByLabel } from '../constants/placementConstants';

export const currencySymbolFromCode = (code) => {
  switch (code) {
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'USD':
    default:
      return '$';
  }
};

export const currencySymbolFromCountry = (country) => {
  switch (country) {
    case 'SPAIN':
      return '€';
    case 'ITALY':
      return '€';
    case 'UNITED_KINGDOM':
      return '£';
    default:
      return '$';
  }
};

const formatDecimal = (decimal, minDigits = 2, maxDigits) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits ?? minDigits,
  });

  return formatter.format(decimal);
};

export const getBidAdjustment = (campaign, placementLabel) => {
  const placementId = findPlacementByLabel(placementLabel)?.id;
  if (!placementId) {
    return 0;
  }
  const bidAdjustment = campaign.bidding.adjustments.find((adjustment) => adjustment.predicate === placementId);
  return bidAdjustment?.percentage ?? 0;
};
export const formatStrategyName = (strategy) => {
  switch (strategy) {
    case 'legacyForSales':
      return 'Dynamic bids - down only';
    case 'autoForSales':
      return 'Dynamic bids - up and down';
    case 'manual':
      return 'Fixed bid';
    default:
      return strategy;
  }
};

export const formatCtr = (data) => `${formatDecimal(data?.ctr ?? 0.0, 2)}%`;

export const formatBid = (data, currencyCode) => `${currencySymbolFromCode(currencyCode)}${formatDecimal(data.bid, 2)}`;

export const formatDefaultBid = (data, currencyCode) => `${currencySymbolFromCode(currencyCode)}${formatDecimal(data.defaultBid, 2)}`;

export const formatMonetaryValue = (data, currencySymbol, fieldName, decimal = 2) => `${currencySymbol}${formatDecimal(data[fieldName] ? data[fieldName] : 0.0, decimal)}`;

export const formatTrueAcos = (data) => `${formatDecimal(data?.trueAcos ?? 0.0, 2)}%`;

export const formatAcos = (data) => `${formatDecimal(data?.acos ?? 0.0, 2)}%`;

export const formatMatchType = (data) => {
  const matchType = data.keywordType;
  switch (matchType) {
    case 'BROAD':
      return 'Broad';
    case 'PHRASE':
      return 'Phrase';
    case 'EXACT':
      return 'Exact';
    case 'TARGETING_EXPRESSION':
      return 'Manual';
    case 'TARGETING_EXPRESSION_PREDEFINED':
      return 'Auto';
    default:
      return matchType;
  }
};

export default formatDecimal;
