<template>
  <Login v-if="$route.path === '/login'" />
  <SignUp v-else-if="$route.path === '/signup'" />
  <Error v-else-if="$route.path === '/error'" />
  <NotFound v-else-if="$route.name === 'notfound'" />
  <Access v-else-if="$route.path === '/access'" />
  <App
    v-else
  />
</template>

<script>
import App from './App';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Error from './pages/Error';
import NotFound from './pages/NotFound';
import Access from './pages/Access';

export default {
  components: {
    App,
    Login,
    Error,
    NotFound,
    Access,
    SignUp,
  },
};
</script>
